import { cdnBase } from '../../const';

export default {
  en_us: {
    langKeys: {
      en_us: 'English (US)',
      en_gb: 'English (UK)',
      en_au: 'English (AU)',
      en_ca: 'English (Canada)',
      fr_fr: 'France',
      nl_nl: 'Netherlands',
      de_de: 'German',
      it_it: 'Italy',
      cz_cz: 'Czech Republic',
      hu_hu: 'Hungary',
      pl_pl: 'Poland',
      ro_ro: 'Romania',
      sk_sk: 'Slovakia',
      mx_mx: 'Mexico',
      es_es: 'Spain',
      tr_tr: 'Turkish',
      hr_hr: 'Croata',
      ru_ru: 'Russian'
    },
    interstitial: {
      heading: 'You are leaving the Spin Master website.',
      copy: `By clicking "continue" you'll be leaving this web site and landing on a third-party site, which is governed by different terms and privacy policies.`,
      continue: 'Continue',
      cancel: 'Cancel'
    },
    ga: {
      copy: 'We use cookies on this site to help improve your experience. By using this site, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third­parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.',
      continue: 'Continue',
      disable: 'Disable Cookies'
    },
    brand: 'Meccano',
    applinks: {
      apple: 'LINK TO APPLE APP',
      google: 'LINK TO GOOGLE APP'
    },
    chooseRegion: 'Choose Language',
    loading: 'Loading',
    select: 'Select',
    clearAll: 'Clear All',
    enterReference: 'Enter Reference Number',
    enterProducts: 'Enter Products',
    enterKeywords: 'Enter Keywords',
    clearSearch: 'Clear Search',
    filters: 'Filters',
    filter: 'Filter',
    details: 'Details',
    learnMore: 'Learn More',
    instructions: 'download Instructions',
    download: 'Download',
    videoInstructions: 'video Instructions',
    selectProductType: 'Select Product Type',
    instructionGuides: 'Instruction Guides',
    instructionGuidesSubtitle: 'Search by product type, keyword (e.g., junior, supercar), or reference number (e.g., 21101, 15202)',
    refernceNumberTitle: 'Reference Number',
    findReferenceNumber: 'Find your reference number',
    productType: 'Product Type',
    keywords: 'Keywords',
    referencePopupTitle: 'Where is my reference number?',
    referencePopupDesc: `Your reference number is right under the age rating on the left-hand side of the product box. You can also find the reference number on the product page on our website.`,
    backToSupport: '',
    warnings: {
      enableIframes: 'You Must Enable iFrames to view this video'
    },
    helmet: {
      siteTitle: ' | Meccano ',
      meta_description: 'A Spin Master Brand website',
      tags: `spin;master;`

    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/products',
          display: 'Products'
        },
        {
          type: 'internal_hash',
          href: '#wheretobuy',
          display: 'Where To Buy'
        },
        {
          type: 'internal',
          href: '/instructions',
          display: 'Instructions'
        },
        {
          type: 'internal',
          href: '/support',
          display: 'Support'
        }
      ]
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Contact Us",
      privacy: 'Privacy Policy',
      terms: 'Terms of Service',
      support: 'Support',
      copy: 'Spin Master Ltd. All Rights Reserved.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
      social_media_links: [
        {
          title: "Youtube",
          img: `${cdnBase}Icons+and+Buttons/Footer_YouTube-Default.png`,
          link: 'https://www.youtube.com/user/meccanotoys'
        },
      ]
    },
    home: {
      title: 'Home',
      whereToBuy: 'Where To Buy',
      videoCTA: {
        src: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`
      },
      sections: {
        banner: {
          slides: [
            {
              heading: "The Meccano Maker's Toolbox",
              copy: 'With over 400 interchangeable building pieces, the Meccano Maker’s Toolbox lets you make and remake 5 dynamic builds: a race car, the Eiffel Tower, a robot , a spaceship and a working catapult!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}F23_Meccano_Hero-Banner-Desktop.jpg`,
                posterMobile: `${cdnBase}F23_Meccano_Hero-Banner-Mobile.jpg`,
                poster_alt: ''
              },
              ctas: [
                {
                  type: 'video',
                  copy: 'watch now',
                  link: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/F23_MeccanoToolBox30sTVC-INTLSMMT23300H_WEB.mp4`
                },
                {
                  type: "link",
                  copy: 'learn more',
                  link: '/products/778988468692'
                },
                {
                  type1: "link",
                  copy1: 'Download Instructions',
                  link1: '/instructions?brand=meccano&referenceId=23201'
                },    

              ]
            },
            {
              heading: 'Junior RC Police Car',
              copy: 'With the Meccano Junior Remote Control Police Car, you can build your own police car, then take control and drive it! ',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/F22_Meccano-Hero-Banner-Desktop.jpg`,
                posterMobile: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/Meccano-Hero-Banner-Mobile.jpg`,
                poster_alt: ''
              },
              ctas: [
                {
                  type: 'video',
                  copy: 'watch now',
                  link: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/F22+Meccano+Police+Car+30sTVC.mp4`
                },
                {
                  type: "link",
                  copy: 'learn more',
                  link: '/products/778988381151'
                }                   
              ]
            },
            {
              heading: 'Build, race, and transform 10 different racing vehicles!',
              copy: 'Build and customize 10 different high-speed racing vehicles with easy-to-use instructions, or use your imagination to invent your own.',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-101-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-10in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'watch now',
                  link: `${cdnBase}videos/10-in-1_MultiModel_15sTVC-INTL_SMMEC21150H_FINAL.mp4`
                },
                ,
                {
                  type: "link",
                  copy: 'learn more',
                  link: '/products/778988358559'
                }
              ]
            },
            {
              heading: 'Build 25 different motorized supercars!',
              copy: 'Challenge yourself to build 25 different luxury dream cars. A 6-volt motor and working head and taillights will bring your builds to life!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-251-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-25in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'watch now',
                  link: `${cdnBase}videos/25-in-1_MultiModel_15sTVC-INTL_SMMMM211_50H_FINAL.mp4`
                },
                {
                  type: 'link',
                  copy: 'learn more',
                  link: '/products/778988374245'
                }            
              ]
            },
            
          ]},
        instructions_videos: {
          bgImage: `${cdnBase}Home-Support-Background-Pieces.png`,
          img_alt: 'nuts and bolts ',
          instructions:
          {
            lookingfor: 'Looking for',
            instructions: ' Instructions',
            or: ' or',
            support: ' Support',
            question_mark: '?',
            cta1: 'Instructions',
            cta1_link: '/instructions',
            cta2: 'Get Help',
            cta2_link: '/support'
          },
          tutorial_videos: {
            watch: 'Watch',
            tutorial_videos: 'Tutorial Videos',
            and_more: 'and More!',
            cta: 'Watch Now',
            src: 'https://www.youtube.com/user/meccanotoys',
            cta_link: `${cdnBase}Home-Support-Image-3.jpg`,
          },
          videoLine: {
            video_src: `${cdnBase}Home-Support-Video_HighRes.mp4`,
            img1: `${cdnBase}Home-Support-Image-2.jpg`,
            img1_alt: 'meccano car',
            img2: `${cdnBase}Home-Support-Image-3.jpg`,
            img2_alt: 'meccano',
            img3: `${cdnBase}meccano_toolbox_strip_image.jpg`,
            img3_alt: 'meccano_toolbox_strip'
          }

        },
        productCarouselHeader: 'Meccano Must-Haves',

        discoverLicensedProducts: {
          header: 'Discover our Licensed Products',
          bgImage: `${cdnBase}`,
          logos: [
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_MonsterJam.png`,
              imgAlt: `Monster Jam`,
              link: '/products?filter=cat_meccano_theme_monster_jam'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_Ducati.png`,
              imgAlt: `Ducati`,
              link: '/products?filter=cat_meccano_theme_ducati'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_John+Deer.png`,
              imgAlt: `John Deere`,
              link: '/products?filter=cat_meccano_theme_john_deere'
            },
          ],
          cta: {
            copy: 'LICENSED PRODUCTS',
            link: '/products?filter=cat_meccano_product_type_licensed'
          }

        },
        featuresProducts: {
          header: 'Meccano Must-Haves',
          bgImage: `${cdnBase}`

        },
        reviewsSlides: {
          slides: [
            {
              copy: 'Perfect gift for child who is into building things.',
              author: '- Norman',
              img: `${cdnBase}Home-Review-Background-Images-Quote1.png`
            },
            {
              copy: 'This toy held up to the hype over the years!',
              author: '- Amy D.',
              img: `${cdnBase}Home-Review-Background-Images-Quote2.png`
            },
            {
              copy: 'My 11 year old nephew loved it!',
              author: '- Christopher M.',
              img: `${cdnBase}Home-Review-Background-Images-Quote3.png`
            }

          ]
        },
        about: {
          heading: 'About Meccano',
          bgImage: `${cdnBase}`,
          redbar: `${cdnBase}Global_Red-Divider.png`,
          redbarAlt: 'divider',
          copy: [
            "Meccano's strong legacy as one of the oldest construction systems in the world began in 1898, when Frank Hornby invented it in England. Across the Atlantic Ocean in 1913, a similar construction set called Erector was introduced in the United States. In 2000, these two innovative brands merged, and began building together under one global brand name –Meccano.",
            "125 years after its invention, Meccano's original values and vision remain the same: to inspire the builders of tomorrow!  From classic vehicles to iconic structures, and everything in between, Meccano offers makers around the world the opportunity to bring their inventions to life.",
            "At Meccano, our objective is to build a better tomorrow by empowering and inspiring the next generation of Makers. <em>The Adventure Builds with Meccano!</em>"
          ],
          btmImg: `${cdnBase}Home-About-Toolbox.png`,
          timeline1: [
            {
              year: '1898',
              copy: 'Frank Hornby invents the first Meccano kit in England',
              img: `${cdnBase}Home-About-1898.png`,
              imgAlt: ''
            },

            {
              year: '1959',
              copy: 'A new factory is built in Calais, northern France',
              img: `${cdnBase}Home-About-1959.png`,
              imgAlt: ''
            },

            {
              year: '2015',
              copy: 'The award-winning Maccanoid hits the market',
              img: `${cdnBase}Home-About-2015.png`,
              imgAlt: ''
            },
            {
              year: '2023',
              copy: '​Meccano celebrates 125 years of building excellence with the launch of the Meccano Maker’s Toolbox!',
              img: `${cdnBase}Meccano-History-2023.png`,
              imgAlt: ''
            }

          ],
          timeline2: [

            {
              year: '1913',
              copy: 'Alfred Carlton Gilbert creates the first Erector Set for kids in the US',
              img: `${cdnBase}Home-About-1913.png`,
              imgAlt: ''
            },

            {
              year: '2000',
              copy: 'Meccano & Erector merge and start building together',
              img: `${cdnBase}Home-About-2000.png`,
              imgAlt: ''
            },

            {
              year: '2017',
              copy: 'Meccano launches new STEM-focused designs',
              img: `${cdnBase}Home-About-2017.png`,
              imgAlt: ''
            }
          ]

        },
        retailers: {
          header: 'Where to Buy',
          bgImg: `${cdnBase}Where-to-Buy-Background.jpg`,
          retailers: [
            {
              name: 'Amazon',
              href: 'https://www.amazon.com/stores/Meccano/page/505FE165-2FC4-4E19-96D2-0326BECA20E8',
              img: `${cdnBase}Retailer+Logos/Amazon.png`
            },
            {
              name: 'Walmart',
              href: 'https://www.walmart.com/search/?query=meccano',
              img: `${cdnBase}Retailer+Logos/Walmart.png`
            }

          ]
        }
      },
    },
    backToSupport: {
      title: 'BACK TO SUPPORT'
    },

    support: {
      title: 'Support',
      subTitle: 'Support',
      redDivider: 'https://meccano2021.s3.amazonaws.com/Global_Red-Divider.png',
      supportBanner: {
        img: {
          src: 'https://meccano2021.s3.amazonaws.com/Image-Band.jpg',
          alt: ''
        },
      },
      softwareDownloadSection: {
        instructions: {
          header: `Looking for
          Instructions?`,
          img: '',
          subHeader: 'Click below to find exactly what you need!',
          ctaLabel: 'FIND INSTRUCTIONS',
          ctaLink: '/instructions'
        },
        customerService: {
          header: 'Customer Service',
          img: '',
          subHeader: 'Missing parts or have a question for us? Our customer service team is here to help!',
          ctaLabel: 'GET HELP',
          ctaLink: 'https://www.spinmaster.com/en-US/customer-support'
        },
        tutorialVids: {
          header: 'Tutorial Videos and More!',
          img: '',
          subHeader: 'Visit our YouTube channel for Meccano tutorial videos, unboxings, and more!',
          ctaLabel: 'WATCH NOW',
          ctaLink: 'https://www.youtube.com/user/meccanotoys'
        },
        softwareSupport: {
          header: 'Software Support',
          header2: '& Updates',
          backToSupport_link: "/support",
          softwareUpdate : {
            title: "M.A.X. and Meccanoid",
            title2: "Software Update",
            desc: "Download our robot update software to get the full robot experience or change your robot's language ",
            forMac: "For Mac",
            forWindows: "For Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          programmingEnv: {
            title: "Micronoid Code",
            title2: "Programming Environment",
            forMac: "For Mac",
            forWindows: "For Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          img: '',
          subHeader: 'Have questions about programming your Meccano Robot? Find information on software support here.',
          ctaLabel: 'LEARN MORE',
          ctaLink: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftwareV1.28.5.zip',
          ctaLinkMac: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftware_V1.23.pkg',
          meccanoid_app:{
            title: 'Meccanoid App',
            desc: 'Do you want to program your robot with your smart device? Download the free app and program movements and/or sounds.',
            googlePlay_link: 'https://play.google.com/store/apps/details?id=com.spinmaster.meccanoidrobot&hl=en',
            googlePlayImg: `${cdnBase}Icons+and+Buttons/playstore%402x.png`,
            googleplay_alt: "Google Play Button",
            appStore_link: 'https://itunes.apple.com/us/app/meccanoid/id961199082?mt=8',
            appStoreImg: `${cdnBase}Icons+and+Buttons/appstore%402x.png`,
            appStore_alt: "Apple App Store Button",
            compatible_devices_title: "COMPATIBLE DEVICES",
            compatible_devices_list: 
            'Apple iOS, iPad Air 9.3.3, iPad Air 2 9.3.3, iPad Pro 9.7 9.3.3, iPad Pro 12.9" 9.3.3, iPad Mini 9.3.3, iPad Mini 2 9.3.2, iPad Mini 3 9.3.3, iPad Mini 4 9.3.3, iPad 3 9.3.3, iPad 4 9.3.3, iPad 4 10 Beta, iPhone SE 9.3.3, iPhone 4s 9.3.3, iPhone 5 9.3.3, iPhone 5s 9.3.3, iPhone 5c 9.0, iPhone 6 9.3.3, iPhone 6 10 Beta, iPhone 6S 9.3.3, iPhone 6 Plus 9.3.3, iPod Touch 5G 9.3.3, iPod Touch 6G 9.3.3, Android, Asus Nexus 7" 6.0.1, LG Nexus 4 4.4.2, LG Nexus 5 6.01, LG Nexus 5x 6.0.1, HTC Nexus 9 6.0.1, HTC One M8 5.0.1, HTC One M9 5.0.2, Huawei P9 6.0.1, LG G2 4.4.2, LG G3 5.0, LG G4 (2) 6.0, Motorola Moto G (2014 / 2nd gen) 4.4.4, Motorola Moto G (2015 / 3rd gen) 5.1.1, Motorola Moto X (2014) 5.0, Motorola Nexus 6 7.0 Beta, Samsung Galaxy S4 4.4.2, Samsung Galaxy S5 5.0, Samsung Galaxy S6 6.0.1, Samsung Galaxy S6 Edge 6.0.1, Samsung Galaxy S7 Edge 6.0.1, Samsung Galaxy S7 6.0.1, Samsung Galaxy Note 2 4.4.2, Samsung Galaxy Note 3 LTE 5.0, Samsung Galaxy Note 4 6.0., Samsung Galaxy Note 5 6.0.1, Galaxy Note Edge 5.1.1, Galaxy Tab S 8,4" 5.0, Sony Xperia Tablet Z2 4.4.4, Sony Xperia Tablet Z3 Compact 4.4.4, Sony Xperia Z2 5.0.2, Sony Xperia Z2 Tablet 5.1.1, Sony Xperia Z3 5.1.1, Sony Xperia Z5 6.0.1'
          }
        }
      }
    },
    collection: {
      title: 'Instructions Guides ',
      nextPage: 'Next Page',
      prevPage: 'Prev Page',
      all: 'All',
      wantIt: 'Want it',
      haveIt: 'Have it',
      flipIt: 'Facts',
      scent: 'Scent',
      rarity: 'Rarity',
      color: 'Color',
      showing: 'Showing',
      filters: [
        'scent',
        'rarity',
        'color'
      ]
    },
    toys: {
      title: 'Toys',
      subTitle: 'Toys',
      learn: 'learn',
      more: 'more'
    },
    toy: {
      title: 'Toy',
      subTitle: 'Toy',
      backToToys: 'Back to Toys',
      features: 'Features',
      image: 'Image',
      contents: 'Contents',
      whereToBuy: 'Where to Buy',
      instructions: 'Instructions',
      videoInstructions: 'Video Instructions',
      brandSite: 'brand site',
      buyNow: 'Buy Now',
      retailers: 'Retailers',
      readmore: 'Read More...'
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Play Video',
      playButton_Alt: 'play button',
      defaultVideoIndex: 0,
      categories: 'Categories',
      catList: ['all', 'commercials', 'influencer'],
      videoList: [
        {
          title: 'Commercial 1',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'Commercial 2',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'influencer Video',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'influencer'],
        },
        // {
        //   title:'Youtube Video',
        //   video:`Sx3ORAO1Y6s`,
        //   thumb: 'https://via.placeholder.com/400x250',
        //   type: 'youtube',
        //   categories: ['all','influencer'],
        // }
      ],
    },
    nomatch: {
      hero: {
        bodyText: "Oops! This page is still under construction.",
        backgroundImageDesktop: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage.jpg`,
        backgroundImageMobile: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage-Mobile.jpg`,
        altText: "",
      },
      ctaLabel: "RETURN TO HOME",
      cta_link: 'https://www.google.com',
      title: 'Page Not Found'
    }
  },
}
import { cdnBase } from '../../const';

export default {
  it_it: {
    langKeys: {
      en_us: 'Inglese (US)',
      en_gb: 'Inglese (UK)',
      en_au: 'Inglese (AU)',
      en_ca: 'Inglese (Canada)',
      fr_fr: 'Francia',
      nl_nl: 'Olanda',
      de_de: 'Germania',
      it_it: 'Italia',
      cz_cz: 'Repubblica Ceca',
      hu_hu: 'Ungheria',
      pl_pl: 'Polonia',
      ro_ro: 'Romania',
      sk_sk: 'Slovacchia',
      mx_mx: 'Messico',
      es_es: 'Spagna',
      tr_tr: 'Turchia',
      hr_hr: 'Croazia',
      ru_ru: 'Russian'
    },
    interstitial: {
      heading: 'Stai lasciando il sito Spin Master.',
      copy: `Facendo clic su "continua" lascerai questo sito Web e atterrerai su un sito di terze parti, che è regolato da termini e politiche sulla privacy diversi.`,
      continue: 'Continua',
      cancel: 'Cancel'
    },
    ga: {
      copy: 'We use cookies on this site to help improve your experience. By using this site, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third­parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.',
      continue: 'Continua',
      disable: 'Disabilita Cookies'
    },
    brand: 'Meccano',
    applinks: {
      apple: 'LINK ALL\'APP APPLE',
      google: 'LINK ALL\'APP GOOGLE'
    },
    chooseRegion: 'Scegli Lingua',
    loading: 'Caricamento',
    select: 'Seleziona',
    clearAll: 'Cancella Tutto',
    enterReference: 'Inserisci il Numero Identificativo',
    enterProducts: 'Inserisci Prodotti',
    enterKeywords: 'Inserisci Parola Chiave',
    clearSearch: 'Pulisci Ricerca',
    filters: 'Filtri',
    filter: 'Filtro',
    details: 'Dettagli',
    learnMore: 'Scopri di più',
    instructions: 'Scarica Istruzioni',
    download: 'Download',
    videoInstructions: 'Istruzioni Video',
    selectProductType:'Select Product Type',
    instructionGuides: 'Guida alle Istruzioni',
    instructionGuidesSubtitle: 'Cerca per tipo di prodotto, parola chiave (es. junior, multimodello), or numero identificativo (es. 21101, 15202)',
    refernceNumberTitle: 'Numero Identificativo',
    findReferenceNumber: 'Trova il tuo numero identificativo',
    productType: 'Tipo di Prodotto',
    keywords: 'Parola Chiave',
    referencePopupTitle: 'Dove trovo il numero identificativo?',
    referencePopupDesc: `Il tuo numero di riferimento si trova proprio sotto la fascia d'età sul lato sinistro della confezione del prodotto. È inoltre possibile trovare il numero di riferimento sulla pagina del prodotto sul nostro sito web.`,
    backToSupport: 'TORNA AL SUPPORTO',
    warnings: {
      enableIframes: 'Devi abilitare iFrames per visualizzare questo video'
    },
    helmet: {
      siteTitle: ' | Meccano ',
      meta_description: 'Un sito Spin Master',
      tags: `spin;master;`

    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/products',
          display: 'Prodotti'
        },
        {
          type: 'internal_hash',
          href: '#wheretobuy',
          display: 'Dove comprare'
        },
        {
          type: 'internal',
          href: '/instructions',
          display: 'Istruzioni'
        },
        {
          type: 'internal',
          href: '/support',
          display: 'Supporto'
        }
      ]
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Contatti",
      privacy: 'Privacy Policy',
      terms: 'Termini del Servizio',
      support: 'Supporto',
      copy: 'Spin Master Ltd. All Rights Reserved.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
      social_media_links: [
        {
          title: "Youtube",
          img: `${cdnBase}Icons+and+Buttons/Footer_YouTube-Default.png`,
          link: 'https://www.youtube.com/user/meccanotoys'
        },
      ]
    },
    home: {
      title: 'Home',
      whereToBuy: 'Dove comprare',
      videoCTA: {
        src: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`
      },
      sections: {
        banner: {
          slides: [
            {
              heading: 'Costruisci, trasforma e divertiti con 10 diversi veicoli da corsa!',
              copy: 'Costruisci e personalizza fino a 10 diversi veicoli ad alta velocità con istruzioni facili da usare, oppure usa la tua immaginazione per inventarne uno tuo.',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-101-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-10in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Guarda ora',
                  link: `${cdnBase}videos/10-in-1_MultiModel_15sTVC-INTL_SMMEC21150H_FINAL.mp4`
                },
                ,
                {
                  type: "link",
                  copy: 'Scopri di più',
                  link: '/products/778988358559'
                }
              ]
            },
            {
              heading: 'Costruisci 25 diversi veicoli motorizzati!',
              copy: 'Sfida te stesso a costruire 25 diverse auto da sogno. Un motore da 6 volt, una carrozzeria unica e i fanali posteriori daranno vita alle tue costruzioni!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-251-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-25in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Guarda ora',
                  link: `${cdnBase}videos/25-in-1_MultiModel_15sTVC-INTL_SMMMM211_50H_FINAL.mp4`
                },
                {
                  type: 'link',
                  copy: 'Scopri di più',
                  link: '/products/778988374245'
                }
              ]
            },
            {
              heading: 'Costruisci il tuo Monster Jam Grave Digger ufficiale!',
              copy: 'Questo Meccano Junior Grave Digger ha sospensioni funzionanti! Usa gli adesivi inclusi per dare al tuo monster truck lo stile che lo contraddistingue!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-MJ-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-GraveDigger-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Guarda ora',
                  link: `${cdnBase}videos/MJ_GraveDigger_20sTVC_SMMJR21_00HFINAL_h264.mov`
                },
                {
                  type: 'link',
                  copy: 'Scopri di più',
                  link: '/products/778988359396'
                }
              ]
            },

          ]
        },
        instructions_videos: {
          bgImage: `${cdnBase}Home-Support-Background-Pieces.png`,
          img_alt: 'nuts and bolts ',
          instructions:
          {
            lookingfor: 'Cerchi',
            instructions: 'istruzioni',
            or: 'o',
            support: 'supporto',
            question_mark: '?',
            cta1: 'Istruzioni',
            cta1_link: '/instructions',
            cta2: 'Aiuto',
            cta2_link: '/support'
          },
          tutorial_videos: {
            watch: 'Guarda',
            tutorial_videos: 'Video tutorial',
            and_more: 'e altro!',
            cta: 'Guarda ora',
            src: 'https://www.youtube.com/user/meccanotoys',
            cta_link: `${cdnBase}Home-Support-Image-3.jpg`,
          },
          videoLine: {
            video_src: `${cdnBase}Home-Support-Video_HighRes.mp4`,
            img1: `${cdnBase}Home-Support-Image-2.jpg`,
            img1_alt: 'meccano car',
            img2: `${cdnBase}Home-Support-Image-3.jpg`,
            img2_alt: 'meccano'
          }

        },
        productCarouselHeader: 'Meccano Must-Haves',

        discoverLicensedProducts: {
          header: 'Scopri i prodotti a licenza',
          bgImage: `${cdnBase}`,
          logos: [
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_MonsterJam.png`,
              imgAlt: `Monster Jam`,
              link: '/products?filter=cat_meccano_theme_monster_jam'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_Ducati.png`,
              imgAlt: `Ducati`,
              link: '/products?filter=cat_meccano_theme_ducati'
            },
          ],
          cta: {
            copy: 'PRODOTTI LICENZA',
            link: '/products?filter=cat_meccano_product_type_licensed'
          }

        },
        featuresProducts: {
          header: 'Meccano Must-Haves',
          bgImage: `${cdnBase}`

        },
        reviewsSlides: {
          slides: [
            {
              copy: 'Regalo perfetto per chi ama costruire.',
              author: '- Norman',
              img: `${cdnBase}Home-Review-Background-Images-Quote1.png`
            },
            {
              copy: 'Questo è un gioco intramontabile!',
              author: '- Amy D.',
              img: `${cdnBase}Home-Review-Background-Images-Quote2.png`
            },
            {
              copy: 'Mio nipote di 11 anni lo adora!',
              author: '- Christopher M.',
              img: `${cdnBase}Home-Review-Background-Images-Quote3.png`
            }

          ]
        },
        about: {
          heading: 'Su Meccano',
          bgImage: `${cdnBase}`,
          redbar: `${cdnBase}Global_Red-Divider.png`,
          redbarAlt: 'divider',
          copy: [
            "La storia di Meccano, uno dei più antichi sistemi di costruzione al mondo, inizia nel 1898, grazie all'intuito e invenzione di Frank Hornby in Inghilterra. Nel 1913, fu introdotto un set di costruzioni simile chiamato Erector, negli Stati Uniti. Nel 2000, questi due marchi innovativi si sono fusi e hanno iniziato a costruire insieme sotto un unico marchio globale: Meccano. Più di 100 anni dopo, i valori originali e l'obiettivo di Meccano rimangono gli stessi: ispirare i costruttori di tutto il mondo a dare vita alle loro invenzioni. Dalle strutture di base alla programmazione robotica ad alta tecnologia, Meccano offre qualcosa per tutti. La missione di Meccano è costruire un domani migliore dando gli strumenti e l'ispirazione ai piccoli e grandi costruttori. <em> Mettiti alla prova con Meccano! </em>",
            "",
            ""
          ],
          btmImg: `${cdnBase}Home-About-Toolbox.png`,
          timeline1: [
            {
              year: '1898',
              copy: 'Frank Hornby inventa il primo kit Meccano in Inghilterra',
              img: `${cdnBase}Home-About-1898.png`,
              imgAlt: ''
            },

            {
              year: '1959',
              copy: 'Una nuova fabbrica viene costruita a Calais, nel nord della Francia',
              img: `${cdnBase}Home-About-1959.png`,
              imgAlt: ''
            },

            {
              year: '2015',
              copy: 'Il pluripremiato Meccanoid fa il suo ingresso nel mercato',
              img: `${cdnBase}Home-About-2015.png`,
              imgAlt: ''
            },

            {
              year: '2021',
              copy: 'Meccano diventa il marchio riconosciuto a livello mondiale che dà forza ai costruttori di domani',
              img: `${cdnBase}Home-About-2021.png`,
              imgAlt: ''
            }
          ],
          timeline2: [

            {
              year: '1913',
              copy: 'Meccano ed Erector si fondono e iniziano a costruire insiemeAlfred Carlton Gilbert crea il primo set Erector per bambini negli Stati Uniti.',
              img: `${cdnBase}Home-About-1913.png`,
              imgAlt: ''
            },

            {
              year: '2000',
              copy: 'Meccano ed Erector si fondono e iniziano a costruire insieme',
              img: `${cdnBase}Home-About-2000.png`,
              imgAlt: ''
            },

            {
              year: '2017',
              copy: 'Meccano lancia nuovi design incentrati sullo STEM',
              img: `${cdnBase}Home-About-2017.png`,
              imgAlt: ''
            }
          ]

        },
        retailers: {
          header: 'Where to Buy',
          bgImg: `${cdnBase}Where-to-Buy-Background.jpg`,
          retailers: [
            {
              name: 'Amazon',
              href: 'https://www.amazon.it/stores/page/28F09D46-45F0-4F67-9FCB-0924DDC4B6B4/?_encoding=UTF8&store_ref=SB_A01923111QXU0S3IDYR59&pd_rd_plhdr=t&aaxitk=22af1aa1af6c7c15c2f1a55b4a9e085b&hsa_cr_id=4218638300702&lp_asins=B08T5WFSF6%2CB074XP4CGF%2CB014H1W2K8&lp_query=meccano&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_mcd_logo&pd_rd_w=I2VZT&pf_rd_p=831df612-8a73-4a74-a4cc-679e0a344675&pd_rd_wg=teW5E&pf_rd_r=MAJ5E2Q21TC2MC9QH01V&pd_rd_r=3314c8a3-2dd4-4a57-ac3f-256a0945cc75',
              img: `${cdnBase}Retailer+Logos/Amazon.png`
            },
          ]
        }
      },
    },
    backToSupport: {
      title: 'BACK TO SUPPORT'
    },

    support: {
      title: 'Supporto',
      subTitle: 'Supporto',
      redDivider: 'https://meccano2021.s3.amazonaws.com/Global_Red-Divider.png',
      supportBanner: {
        img: {
          src: 'https://meccano2021.s3.amazonaws.com/Image-Band.jpg',
          alt: ''
        },
      },
      softwareDownloadSection: {
        instructions: {
          header: `Cerchi istruzioni?`,
          img: '',
          subHeader: 'Clicca sotto per trovare ciò che cerchi!',
          ctaLabel: 'TROVA LE ISTRUZIONI',
          ctaLink: '/instructions'
        },
        customerService: {
          header: 'Servizio Clienti',
          img: '',
          subHeader: 'Parti mancanti o hai una domanda per noi? Il nostro team di assistenza clienti è qui per aiutarti!',
          ctaLabel: 'RICEVI AIUTO',
          ctaLink: 'https://www.spinmaster.com/en-US/customer-support'
        },
        tutorialVids: {
          header: 'Video tutorial e altro!',
          img: '',
          subHeader: 'Visita il nostro canale YouTube per video tutorial di Meccano, unboxing e molto altro!',
          ctaLabel: 'GUARDA ORA',
          ctaLink: 'https://www.youtube.com/user/meccanotoys'
        },
        softwareSupport: {
          header: 'Software Support',
          header2: '& Updates',
          backToSupport_link: "/support",
          softwareUpdate : {
            title: "M.A.X. and Meccanoid",
            title2: "Software Update",
            desc: "Scarica il nostro software di aggiornamento del robot per ottenere l'esperienza completa del robot o cambia la lingua del tuo robot",
            forMac: "Per Mac",
            forWindows: "Per Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          programmingEnv: {
            title: "Micronoid Code",
            title2: "Programming Environment",
            forMac: "For Mac",
            forWindows: "For Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          img: '',
          subHeader: 'Hai domande sulla programmazione del tuo Robot Meccano? Trova informazioni sul supporto software qui.',
          ctaLabel: 'SCOPRI DI PIU\'',
          ctaLink: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftwareV1.28.5.zip',
          ctaLinkMac: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftware_V1.23.pkg',
          meccanoid_app:{
            title: 'Meccanoid App',
            desc: "Vuoi programmare il tuo robot con il tuo dispositivo smart? Scarica l'app gratuita e programma movimenti e/o suoni.",
            googlePlay_link: 'https://play.google.com/store/apps/details?id=com.spinmaster.meccanoidrobot&hl=en',
            googlePlayImg: `${cdnBase}Icons+and+Buttons/playstore%402x.png`,
            googleplay_alt: "Google Play Button",
            appStore_link: 'https://itunes.apple.com/us/app/meccanoid/id961199082?mt=8',
            appStoreImg: `${cdnBase}Icons+and+Buttons/appstore%402x.png`,
            appStore_alt: "Apple App Store Button",
            compatible_devices_title: "DEVICE COMPATIBILI",
            compatible_devices_list: 
            'Apple iOS, iPad Air 9.3.3, iPad Air 2 9.3.3, iPad Pro 9.7 9.3.3, iPad Pro 12.9" 9.3.3, iPad Mini 9.3.3, iPad Mini 2 9.3.2, iPad Mini 3 9.3.3, iPad Mini 4 9.3.3, iPad 3 9.3.3, iPad 4 9.3.3, iPad 4 10 Beta, iPhone SE 9.3.3, iPhone 4s 9.3.3, iPhone 5 9.3.3, iPhone 5s 9.3.3, iPhone 5c 9.0, iPhone 6 9.3.3, iPhone 6 10 Beta, iPhone 6S 9.3.3, iPhone 6 Plus 9.3.3, iPod Touch 5G 9.3.3, iPod Touch 6G 9.3.3, Android, Asus Nexus 7" 6.0.1, LG Nexus 4 4.4.2, LG Nexus 5 6.01, LG Nexus 5x 6.0.1, HTC Nexus 9 6.0.1, HTC One M8 5.0.1, HTC One M9 5.0.2, Huawei P9 6.0.1, LG G2 4.4.2, LG G3 5.0, LG G4 (2) 6.0, Motorola Moto G (2014 / 2nd gen) 4.4.4, Motorola Moto G (2015 / 3rd gen) 5.1.1, Motorola Moto X (2014) 5.0, Motorola Nexus 6 7.0 Beta, Samsung Galaxy S4 4.4.2, Samsung Galaxy S5 5.0, Samsung Galaxy S6 6.0.1, Samsung Galaxy S6 Edge 6.0.1, Samsung Galaxy S7 Edge 6.0.1, Samsung Galaxy S7 6.0.1, Samsung Galaxy Note 2 4.4.2, Samsung Galaxy Note 3 LTE 5.0, Samsung Galaxy Note 4 6.0., Samsung Galaxy Note 5 6.0.1, Galaxy Note Edge 5.1.1, Galaxy Tab S 8,4" 5.0, Sony Xperia Tablet Z2 4.4.4, Sony Xperia Tablet Z3 Compact 4.4.4, Sony Xperia Z2 5.0.2, Sony Xperia Z2 Tablet 5.1.1, Sony Xperia Z3 5.1.1, Sony Xperia Z5 6.0.1'
          }
        }
      }
    },
    toys: {
      title: 'Giochi',
      subTitle: 'Giochi',
      learn: 'Scopri',
      more: 'di più'
    },
    toy: {
      title: 'Giochi',
      subTitle: 'Giochi',
      backToToys: 'Torna a Giochi',
      features: 'Caratteristiche',
      image: 'Immagine',
      contents: 'Contenuti',
      whereToBuy: 'Dove comprare',
      instructions: 'Istruzioni',
      videoInstructions: 'Istruzioni Video',
      brandSite: 'Sito',
      buyNow: 'Compra ora',
      retailers: 'Negozi',
      readmore: 'Leggi di più…'
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Play Video',
      playButton_Alt: 'play button',
      defaultVideoIndex: 0,
      categories: 'Categories',
      catList: ['all', 'commercials', 'influencer'],
      videoList: [
        {
          title: 'Commercial 1',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'Commercial 2',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'influencer Video',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'influencer'],
        },
        // {
        //   title:'Youtube Video',
        //   video:`Sx3ORAO1Y6s`,
        //   thumb: 'https://via.placeholder.com/400x250',
        //   type: 'youtube',
        //   categories: ['all','influencer'],
        // }
      ],
    },
    nomatch: {
      hero: {
        bodyText: "Ops! Questa pagina è in lavorazione.",
        backgroundImageDesktop: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage.jpg`,
        backgroundImageMobile: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage-Mobile.jpg`,
        altText: "",
      },
      ctaLabel: "TORNA ALLA HOME",
      cta_link: 'https://www.google.com',
      title: 'Pagina non trovata'
    }
  },
}
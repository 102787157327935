import React, { Component } from 'react';
import './Footer.css';
import { lang } from '../../../il8n/lang';
import { Link } from 'react-router-dom';
import logoSM from './Images/logo-sm.png';
import { toggleInterstitial } from '../../../Actions/Index';
import { connect } from 'react-redux';

/**
 * The main application footer.
 * @constructor
 */

class Footer extends Component {
  /**
  * Returns the current year.
  */
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <footer className='footer'>
      <div className="wrap">
        <a className='footer__logo' href='http://www.spinmaster.com/' target='_blank' rel='noopener noreferrer'>
          <img src={logoSM} alt={lang.footer.brand} />
        </a>
        <div className="footer_left">
          <div className='footer__links '>
              <a className="desktop-footer" href={lang.footer.contactLink} target="_blank">
                {lang.footer.contactUs}
              </a>
              {/* <a onClick={() => this.props.dispatch(toggleInterstitial(true, lang.footer.privacyLink ))} target="_blank">
                {lang.footer.privacy}
              </a> */}
              <a className="desktop-footer" href={lang.footer.privacyLink}  target="_blank">
                {lang.footer.privacy}
              </a>
                <a className="desktop-footer"  href={lang.footer.termsLink } target="_blank">
                {lang.footer.terms}
              </a>
              <a className="desktop-footer"  href={lang.footer.supportLink} target="_blank">
                {lang.footer.support}
              </a>
              <a className="desktop-footer" href={lang.footer.cookiesLink} id="cookiepref-footer">
                {lang.footer.cookiesPref}
              </a>
            </div>
            <div className='footer__copyright desktop-legal'>
              {`© ${ this.getYear() } ${ lang.footer.copy }`}
            </div>
            <div className="social-media-links">
            {lang.footer.social_media_links.map((icon, index) => (
              <div key={icon.title} className='social_media_icon' onClick={() => this.props.dispatch(toggleInterstitial(true, icon.link ))}>
                <img className="socialmedia-img"  id={`${icon.title}`} src={icon.img} alt={icon.title} />
                </div>
              ))}
            </div>
        </div>

      </div>
    </footer>
    );
  }
}

function mapStateToProps(state) {
  return { lang: state.lang };
}


export default connect(mapStateToProps)(Footer);
import { lang } from '../il8n/lang';

function getDefaultVid(){
  return lang.videos.hasOwnProperty('defaultVideoIndex') ? lang.videos.videoList[lang.videos.defaultVideoIndex] : lang.videos.videoList[0];
}

const initialState = {
  lang: lang.getLanguage(),
  activeVid: getDefaultVid(),
  products: {},
  collectibles: {},
  haveIt: [],
  wantIt: [],
  likeIt: [],
  flipIt: [],
  interstitial: {
    active: false,
    url: '',
    label: ''
  },
  gaToggle: {
    active: true,
    status: true
  },
  modal: {
    active: false
  }
};

const mainReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHANGE_LANG':
      lang.setLanguage(action.lang);
      return Object.assign({}, state, {
        lang: action.lang,
        activeVid: getDefaultVid()
      });
    case 'TOGGLE_GA':
      return Object.assign({}, state, {
        gaToggle: {
          active: action.gaToggle.active,
          status: action.gaToggle.status
        }
      });
    case 'TOGGLE_INTERSTITIAL':
      return Object.assign({}, state, {
        interstitial: {
          active: action.interstitial.active,
          url: action.interstitial.url,
          label: action.interstitial.label,
          isIframe: action.interstitial.isIframe
        }
      });
    case 'CHANGE_ACTIVEVID':
      return Object.assign({}, state, {
        activeVid: action.activeVid
      });
    case 'CHANGE_PRODUCTS':
      return Object.assign({}, state, {
        products: action.products
      });
    case 'CHANGE_INSTRUCTIONS':
      return Object.assign({}, state, {
        instructions: action.instructions
    });
    case 'CHANGE_CATEGORIES':
      return Object.assign({}, state, {
        categories: action.categories
    });
    case 'CHANGE_PRODUCT':
      return Object.assign({}, state, {
        product: action.product
      });
    case 'CHANGE_COLLECTIBLES':
      return Object.assign({}, state, {
        collectibles: action.collectibles
      });

    case 'CHANGE_FLIP':
      // TODO: Not DRY, look @ CHANGE_HAVEIT, these can be combined
      if(state.flipIt.indexOf(action.flipIt) > -1){
        // Removal
        return Object.assign({}, state, {
          ...state,
          flipIt: state.flipIt.filter((item, index) => index !== state.flipIt.indexOf(action.flipIt))
        });
      } else {
        // Addition
        return Object.assign({}, state, {
          ...state,
          flipIt: [...state.flipIt, action.flipIt]
        });
      }
    case 'CHANGE_WANTIT':
      // TODO: Not DRY, look @ CHANGE_HAVEIT, these can be combined
      if(state.wantIt.indexOf(action.wantIt) > -1){
        // Removal
        return Object.assign({}, state, {
          ...state,
          wantIt: state.wantIt.filter((item, index) => index !== state.wantIt.indexOf(action.wantIt))
        });
      } else {
        // Addition
        return Object.assign({}, state, {
          ...state,
          wantIt: [...state.wantIt, action.wantIt],
          haveIt: state.haveIt.filter((item, index) => index !== state.haveIt.indexOf(action.wantIt))
        });
      }
    case 'CHANGE_HAVEIT':
    // TODO: Not DRY, look @ CHANGE_WANTIT, these can be combined
      if(state.haveIt.indexOf(action.haveIt) > -1){
        // Removal
        return Object.assign({}, state, {
          ...state,
          haveIt: state.haveIt.filter( (item, index) => index !== state.haveIt.indexOf(action.haveIt))
        });
      } else {
        // Addition
        return Object.assign({}, state, {
          ...state,
          haveIt: [...state.haveIt, action.haveIt],
          wantIt: state.wantIt.filter((item, index) => index !== state.wantIt.indexOf(action.haveIt))
        });
      }
    case 'CHANGE_LIKEIT':
      if(state.likeIt.indexOf(action.likeIt) > -1){
        // Removal
        return Object.assign({}, state, {
          ...state,
          likeIt: state.likeIt.filter( (item, index) => index !== state.likeIt.indexOf(action.likeIt))
        });
      } else {
        // Addition
        return Object.assign({}, state, {
          ...state,
          likeIt: [...state.likeIt, action.likeIt]
        });
      }
    default:
      return initialState;
  }
};

export default mainReducer;

import { cdnBase } from '../../const';

export default {
  fr_fr: {
    langKeys: {
      en_us: 'Anglais (US)',
      en_gb: 'Anglais (UK)',
      en_au: 'Anglais (AU)',
      en_ca: 'Anglais (Canada)',
      fr_fr: 'France',
      nl_nl: 'Pays-Bas',
      de_de: 'Allemagne',
      it_it: 'Italie',
      cz_cz: 'République Tchèque',
      hu_hu: 'Hongrie',
      pl_pl: 'Pologne',
      ro_ro: 'Roumanie',
      sk_sk: 'Slovaquie',
      mx_mx: 'Mexique',
      es_es: 'Espagne',
      tr_tr: 'Turquie',
      hr_hr: 'Croate',
      ru_ru: 'Russie'
    },
    interstitial: {
      heading: 'Vous quittez le site web Meccano de Spin Master.',
      copy: `En cliquant sur "Continuer", vous allez quitter cette page et vous rendre sur le site d'un tiers régi par des conditions et des politiques de confidentialité différentes.`,
      continue: 'Continuer',
      cancel: 'Annuler'
      
    },
    ga: {
      copy: `Nous utilisons des cookies sur ce site pour améliorer votre expérience. En utilisant ce site, vous consentez à l'utilisation de cookies. Notre politique en matière de cookies explique ce que sont les cookies, comment nous les utilisons, comment des tiers avec lesquels nous pouvons être partenaires peuvent utiliser des cookies sur le service, vos choix concernant les cookies et d'autres informations sur les cookies.`,
      continue: 'Continuer',
      disable: 'Désactiver les cookies'
    },
    brand: 'Meccano',
    applinks: {
      apple: 'LINK TO APPLE APP',
      google: 'LINK TO GOOGLE APP'
    },
    chooseRegion: 'Choisir la langue',
    loading: 'Chargement',
    select: 'Sélectionner',
    clearAll: 'Effacer tout',
    clearSearch: 'Effacer la recherche',
    enterReference: 'Saisir le numéro de référence',
    enterProducts: 'Saisir les produits',
    enterKeywords: 'Entrer des mots-clés',
    filters: 'Filtres',
    filter: 'Filtre',
    details: 'Détails',
    learnMore: 'En Savoir Plus',
    instructions: 'Télécharger les notices',
    download: 'Télècharger',
    videoInstructions: 'Vidéos démos',
    instructionGuides: 'Notices de montage',
    instructionGuidesSubtitle: 'Recherche par type de produit, mot clé (ex : junior, voiture, supercar) ou référence produit (ex : 21101, 15202)',
    refernceNumberTitle: 'Numéro de référence',
    findReferenceNumber: 'Trouvez votre numéro de référence',
    productType: 'Type de produit',
    selectProductType: 'Sélectionner le type de produit',
    keywords: 'mots clés',
    referencePopupTitle: 'Où se trouve mon numéro de référence ?',
    referencePopupDesc: `Votre numéro de référence se trouve juste en dessous de la classe d'âge, sur le côté gauche de la boîte du produit. Vous pouvez également trouver le numéro de référence sur la page du produit sur notre site web.`,
    backToSupport: 'RETOUR À L`ASSISTANCE',
    warnings: {
      enableIframes: 'Vous devez activer les iFrames pour voir cette vidéo'
    },
    helmet: {
      siteTitle: '| Meccano',
      meta_description: `Un site web de la marque Spin Master`,
      tags: `spin;master;`

    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/products',
          display: 'Les produits'
        },
        {
          type: 'internal_hash',
          href: '#wheretobuy',
          display: 'Où acheter ?'
        },
        {
          type: 'internal',
          href: '/instructions',
          display: 'Les notices'
        },
        {
          type: 'internal',
          href: '/support',
          display: 'AIDE'
        }
      ]
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Nous contacter",
      privacy: 'Politique de confidentialité',
      terms: `Conditions d'utilisation`,
      support: 'Aide',
      copy: 'Spin Master Ltd. Tous droits réservés.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
      social_media_links: [
        {
          title: "Youtube",
          img: `${cdnBase}Icons+and+Buttons/Footer_YouTube-Default.png`,
          link: 'https://www.youtube.com/playlist?list=PL0wwRqT4b_QLc3i0xYlv35NtTuLXb79cF'
        },
      ]
    },

  backToSupport: {
      title: 'RETOUR AU SOUTIEN'
    },
    home: {
      title: 'Accueil',
      whereToBuy: 'Où acheter ?',
      videoCTA: {
        src: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`
      },
      sections: {
        banner: { 
          slides: [
            {
              heading: "Mallette de Construction",
              copy: 'Découvrez les 5 modèles les plus iconiques de Meccano, à construire soi-même ! 1 voiture de course, la Tour Eiffel, 1 robot, 1 catapulte, 1 vaisseau spatial ou bien d`autres à l`aide de votre imagination (1 à la fois).',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}F23_Meccano_Hero-Banner-Desktop.jpg`,
                posterMobile: `${cdnBase}F23_Meccano_Hero-Banner-Mobile.jpg`,
                poster_alt: ''
              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Regarder maintenant',
                  link: `${cdnBase}MECCANO_MALLETTE_15s_WEB_720p_4Mo-encode.mp4`
                },
                {
                  type: "link",
                  copy: 'En savoir plus',
                  link: '/products/778988468692'
                },
                {
                  type1: "link",
                  copy1: 'Télécharger les notices',
                  link1: '/instructions?brand=meccano&referenceId=23201'
                },    

              ]
            },
            {
              heading: 'Ma Voiture de Police RC - Meccano Junior',
              copy: 'Construisez une magnifique voiture de police RC à coffre fonctionnel pour vivre vos propres missions de sauvetages. Grâce à sa fréquence de 2,4 GHz, la radiocommande vous permet de contrôler votre voiture jusqu`à 100 mètres de distance !',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/F22_Meccano-Hero-Banner-Desktop.jpg`,
                posterMobile: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/Meccano-Hero-Banner-Mobile.jpg`,
                poster_alt: ''
              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Regarder maintenant',
                  link: `${cdnBase}Hero-banner-update/Assets+for+Dev+4/F22+Meccano+Police+Car+30sTVC.mp4`
                },
                {
                  type: "link",
                  copy: 'En savoir plus',
                  link: '/products/778988381151'
                },
                {
                  type1: "link",
                  copy1: 'Télécharger les notices',
                  link1: '/instructions?brand=meccano&referenceId=22101'
                },                    
              ]
            },
            {
              heading: 'Véhicules de Course 10 Modèles - Meccano',
              copy: 'Construisez et personnalisez 10 bolides super rapides comme 1 voiture de course, 1 avion de chasse, 1 trimaran (1 à la fois) ou utilisez votre imagination pour créer votre propre véhicule !',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-101-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-10in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Regarder maintenant',
                  link: `${cdnBase}videos/10-in-1_MultiModel_15sTVC-INTL_SMMEC21150H_FINAL.mp4`
                },
                ,
                {
                  type: "link",
                  copy: 'En savoir plus',
                  link: '/products/778988358559'
                },
                {
                  type1: "link",
                  copy1: 'Télécharger les notices',
                  link1: '/instructions?brand=meccano&referenceId=21201'
                }, 
              ]
            },
            {
              heading: 'SuperCar 25 Modèles Motorisés - Meccano',
              copy: 'Construisez une superbe SuperCar ou l’un des 24 autres modèles de véhicules à moteur et feux lumineux ! Le moteur électrique de 6 volts et les voyants lumineux donnent vie à votre construction !',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-251-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-25in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Regarder maintenant',
                  link: `${cdnBase}videos/25-in-1_MultiModel_15sTVC-INTL_SMMMM211_50H_FINAL.mp4`
                },
                {
                  type: 'link',
                  copy: 'En savoir plus',
                  link: '/products/778988374245'
                },
                {
                  type1: "link",
                  copy1: 'Télécharger les notices',
                  link1: '/instructions?brand=meccano&referenceId=21202'
                },            
              ]
            },
            // {
            //   heading: `NOUVEAU ! 
            //   Véhicules de course 10 Modèles !`,
            //   copy: `Construis et personnalise 10 bolides super rapides à l'aide des instructions et des outils fournis !`,
            //   type: 'img',
            //   bg: {
            //     src: '',
            //     poster: `${cdnBase}Meccano-Hero-101-Banner-V3.jpg`,
            //     posterMobile: `${cdnBase}MobileHome-Hero-Banner-10in1-V3.jpg`,
            //     poster_alt: ''

            //   },
            //   ctas: [
            //     {
            //       type: 'video',
            //       copy: 'Regarder maintenant',
            //       link: `${cdnBase}videos/10-in-1_MultiModel_15sTVC-INTL_SMMEC21150H_FINAL.mp4`
            //     },
            //     ,
            //     {
            //       type: "link",
            //       copy: 'En savoir plus',
            //       link: '/products/778988358559'
            //     }
            //   ]
            // },
            // {
            //   heading: `NOUVEAU ! 
            //   Super Car 25 Modèles !`,
            //   copy: `Découvre ce coffret de 25 voitures de rêve à construire. Le moteur à vitesse variable et les feux avant et arrière lumineux donneront vie à tes constructions !`,
            //   type: 'img',
            //   bg: {
            //     src: '',
            //     poster: `${cdnBase}Meccano-Hero-251-Banner-V3.jpg`,
            //     posterMobile: `${cdnBase}MobileHome-Hero-Banner-25in1-V3.jpg`,
            //     poster_alt: ''

            //   },
            //   ctas: [
            //     {
            //       type: 'video',
            //       copy: 'Regarder maintenant',
            //       link: `${cdnBase}videos/25-in-1_MultiModel_15sTVC-INTL_SMMMM211_50H_FINAL.mp4`
            //     },
            //     {
            //       type: 'link',
            //       copy: 'En savoir plus',
            //       link: '/products/778988374245'
            //     }
            //   ]
            // },
            // {
            //   heading: `NOUVEAU MECCANO JUNIOR ! 
            //   Monster Jam Grave Digger !`,
            //   copy: `Construis ce véhicule géant à rétro friction et suspensions fonctionnelles !`,
            //   type: 'img',
            //   bg: {
            //     src: '',
            //     poster: `${cdnBase}Meccano-Hero-MJ-Banner-V3.jpg`,
            //     posterMobile: `${cdnBase}MobileHome-Hero-Banner-GraveDigger-V3.jpg`,
            //     poster_alt: ''

            //   },
            //   ctas: [
            //     {
            //       type: 'video',
            //       copy: 'Regarder maintenant',
            //       link: `${cdnBase}videos/MJ_GraveDigger_20sTVC_SMMJR21_00HFINAL_h264.mov`
            //     },
            //     {
            //       type: 'link',
            //       copy: 'En savoir plus',
            //       link: '/products/778988359396'
            //     }
            //   ]
            // },

          ]
        },
        instructions_videos: {
          bgImage: `${cdnBase}Home-Support-Background-Pieces.png`,
          img_alt: 'écrous et boulons',
          instructions:
          {
            lookingfor: 'Vous cherchez ',
            instructions: 'une notice',
            or: 'ou ',
            support: 'de l`aide',
            question_mark: '?',
            cta1: 'Notices de montage',
            cta1_link: '/instructions',
            cta2: `Obtenir de l'aide`,
            cta2_link: '/support'
          },
          tutorial_videos: {
            watch: 'Regarder des',
            tutorial_videos: 'vidéos',
            and_more: 'et bien plus encore !',
            cta: 'Regarder maintenant',
            src: 'https://www.youtube.com/playlist?list=PL0wwRqT4b_QLc3i0xYlv35NtTuLXb79cF',
            cta_link: `${cdnBase}Home-Support-Image-3.jpg`,
          },
          videoLine: {
            video_src: `${cdnBase}Home-Support-Video_HighRes.mp4`,
            img1: `${cdnBase}Home-Support-Image-2.jpg`,
            img1_alt: 'meccano car',
            img2: `${cdnBase}Home-Support-Image-3.jpg`,
            img2_alt: 'meccano',
            img3: `${cdnBase}meccano_toolbox_strip_image.jpg`,
            img3_alt: 'meccano_toolbox_strip'
          }

        },
        productCarouselHeader: 'Les incontournables Meccano',

        discoverLicensedProducts: {
          header: 'Découvre nos produits sous licence',
          bgImage: `${cdnBase}`,
          logos: [
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_MonsterJam.png`,
              imgAlt: `Monster Jam`,
              link: '/products?filter=cat_meccano_theme_monster_jam_fr'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_Ducati.png`,
              imgAlt: `Ducati`,
              link: '/products?filter=cat_meccano_theme_ducati_fr'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_John+Deer.png`,
              imgAlt: `John Deere`,
              link: '/products?filter=cat_meccano_theme_john_deere_fr'
            },
          ],
          cta: {
            copy: 'NOS LICENCES',
            link: '/products?filter=cat_meccano_product_type_licensed_fr'
          }

        },
        featuresProducts: {
          header: 'Les incontournables Meccano',
          bgImage: `${cdnBase}`

        },
        reviewsSlides: {
          slides: [
            {
              copy: 'C`est un cadeau idéal pour les fans de construction.',
              author: '- Paul',
              img: `${cdnBase}Home-Review-Background-Images-Quote1.png`
            },
            {
              copy: 'Meccano est un incontournable et une valeur sûre !',
              author: '- Amélie',
              img: `${cdnBase}Home-Review-Background-Images-Quote2.png`
            },
            {
              copy: 'Mon neveu de 8 ans a adoré !',
              author: '- Christophe',
              img: `${cdnBase}Home-Review-Background-Images-Quote3.png`
            }

          ]
        },
        about: {
          heading: 'A propos de Meccano',
          bgImage: `${cdnBase}`,
          redbar: `${cdnBase}Global_Red-Divider.png`,
          redbarAlt: 'divider',
          copy: [
            "L'héritage de Meccano, l'un des plus anciens systèmes de construction au monde, a commencé en 1898, lorsque Frank Hornby l'a inventé en Angleterre. Meccano est un jeu de construction à base de pièces en métal dont le but est l'initiation à la mécanique.",
            "Ces bandes métalliques perforées de trous à intervale régulier s'assemblent au moyen de visses et d'écrous. Cette invention est originale car l'écart entre les trous est toujours identique. La première usine Meccano ouvre ses portes en France à Paris Belleville en 1921. Une usine plus grande ouvrira à Calais en 1959.",
            "Après plus de 120 ans d'histoire, les valeurs et objectifs de Meccano restent les mêmes : inspirer les constructeurs du monde entier et donner vie à leur imagination. Meccano offre à tout le monde la possibilité de créer, recréer et d'être fier de ses constructions. L'aventure ne fait que commencer avec Meccano !"
          ],
          btmImg: `${cdnBase}Home-About-Toolbox.png`,
          timeline1: [
            {
              year: '1898',
              copy: 'Frank Hornby invente le premier kit Meccano en Angleterre.',
              img: `${cdnBase}Home-About-1898.png`,
              imgAlt: ''
            },
            {
              year: '1959',
              copy: 'Une nouvelle usine est construite à Calais, en France.',
              img: `${cdnBase}Home-About-1959.png`,
              imgAlt: ''
            },
            {
              year: '2015',
              copy: `L'innovation Meccanoid arrive sur le marché.`,
              img: `${cdnBase}Home-About-2015.png`,
              imgAlt: ''
            },
            {
              year: '2021',
              copy: `Meccano célèbre 125 ans d'excellence en matière de construction avec le lancement de la Mallette de construction Meccano de 437 pièces.`,
              img: `${cdnBase}Home-About-2021.png`,
              imgAlt: ''
            }
          ],
          timeline2: [
            {
              year: '1913',
              copy: 'Alfred Carlton Gilbert crée le premier jeu Erector pour enfants aux États-Unis.',
              img: `${cdnBase}Home-About-1913.png`,
              imgAlt: ''
            },
            {
              year: '2000',
              copy: 'Meccano et Erector fusionnent et commencent à construire ensemble.',
              img: `${cdnBase}Home-About-2000.png`,
              imgAlt: ''
            },
            {
              year: '2017',
              copy: `Meccano lance de nouveaux modèles axés sur les produits STEAM.`,
              img: `${cdnBase}Home-About-2017.png`,
              imgAlt: ''
            },
          ]

        },

        collectAll: {
          title: "Collect them all!",
          img: `${cdnBase}Images+%2B+Illustrations/collectors-glamfoxy.png`,
          img_alt: "Glam Foxy zoobles",
          downloads: [
            {
              title: "Zoobles & Z-Girlz",
              desc: "25 + to collect!",
              img: `${cdnBase}Collector+Sheets/zoobles-zgirlz-thumbnail.jpg`,
              cta: "Download",
              cta_link: `${cdnBase}`
            },
            {
              title: "Opposite Obsessed",
              desc: "8 to collect!",
              img: `${cdnBase}Collector+Sheets/opposite-obsessed-thumbnail.jpg`,
              cta: "Download",
              cta_link: `${cdnBase}`
            }
          ]
        },
        retailers: {
          header: 'Où acheter ?',
          bgImg: `${cdnBase}Where-to-Buy-Background.jpg`,
          retailers: [
            {
              name: 'Boutique officielle Meccano',
              href: 'https://www.meccanodirect.fr/',
              img: `${cdnBase}Retailer+Logos/Meccano.png`
            },
            {
              name: 'Joue Club',
              href: 'https://www.joueclub.fr/contenu/resultat-de-recherche-produits.html?searchText=meccano',
              img: `${cdnBase}Retailer+Logos/JoueClub.png`
            },
            {
              name: 'KingJouet',
              href: 'https://www.king-jouet.com/?recherche=meccan',
              img: `${cdnBase}Retailer+Logos/KingJouet.png`
            },
            {
              name: 'La Grand Recre',
              href: 'https://www.lagranderecre.fr/la-grande-recre/resultat-d-une-recherche-produits.html?facetFilters%5Bf_100445%5D%5B230047%5D=1&searchText=meccano&storeStockFilter=0&webStoreStockFilter=0',
              img: `${cdnBase}Retailer+Logos/LaGrandeRecre.png`
            },       
            // {
            //   name: 'PicWicToys',
            //   href: 'https://www.picwictoys.com/u/meccano-90',
            //   img: `${cdnBase}Retailer+Logos/PicWicToys.png`
            // },
            // {
            //   name: 'Maxitoys',
            //   href: 'https://www.maxitoys.fr/marques/meccano.html',
            //   img: `${cdnBase}Retailer+Logos/MaxiToys.png`
            //},
            {
              name: 'Sajou',
              href: 'https://www.jouets-sajou.com/index.php?fc=module&module=leoproductsearch&controller=productsearch&txt_not_found=Aucun+produit+trouv%C3%A9&leoproductsearch_static_token=877ebf8bb303361a1d559212913123f9&search_query=meccano',
              img: `${cdnBase}SAJOU.png`
            },
            
            {
              name: 'E Leclerc',
              href: 'https://www.e.leclerc/cat/meccano',
              img: `${cdnBase}Retailer+Logos/ELeclerc.png`
            },
            {
              name: 'Carrefour',
              href: 'https://www.carrefour.fr/s?q=meccano',
              img: `${cdnBase}Retailer+Logos/Carrefour.png`
            },
            {
              name: 'Auchan',
              href: 'https://www.auchan.fr/recherche?text=meccano',
              img: `${cdnBase}Retailer+Logos/Auchan.png`
            },
            // {
            //   name: 'Cora',
            //   href: 'https://www.cora.fr/',
            //   img: `${cdnBase}Retailer+Logos/Cora.png`
            // },
            
            {
              name: 'Amazon',
              href: 'https://www.amazon.fr/stores/Meccano/page/6ABB3D90-AD54-4B59-B0B6-773C5A6F4F2A?ref_=ast_bln',
              img: `${cdnBase}Retailer+Logos/Amazon.png`
            },
            {
              name: 'Cultura',
              href: 'https://www.cultura.com/univers-enfant/jeux-jouets/jeux-de-construction/jeux-de-construction-mecanique/meccano.html',
              img: `${cdnBase}Retailer+Logos/Cultura.png`
            },
            {
              name: 'Fnac',
              href: 'http://recherche.fnac.com/SearchResult/ResultList.aspx?SCat=0%211&Search=meccano&sft=1&sa=0',
              img: `${cdnBase}fnac+logo.jpeg`
            },
            {
              name: 'C-discount',
              href: 'https://www.cdiscount.com/search/10/meccano.html#_his_',
              img: `${cdnBase}Retailer+Logos/cDiscount.png`
            },
            {
              name: 'SMYTHS TOYS',
              href: 'https://www.smythstoys.com/fr/fr-fr/search/?text=meccano ',
              img:  `${cdnBase}Smyths+logo.jpeg`
            },
            {
              name: 'Robot Advance',
              href: 'https://www.robot-advance.com/cat-meccano-199.htm',
              img: `${cdnBase}Retailer+Logos/RobotAdvance.png`
            },
          ],
          // retailers2: [
          //   {
          //     name: 'E Leclerc',
          //     href: 'https://www.e.leclerc/cat/meccano',
          //     img: `${cdnBase}E-Leclerc-logo.jpg`
          //   },
          //   {
          //     name: 'Carrefour',
          //     href: 'https://www.carrefour.fr/s?q=meccano',
          //     img: `${cdnBase}Carrefour-logo.jpg`
          //   },
          //   {
          //     name: 'Auchan',
          //     href: 'https://www.auchan.fr/recherche?text=meccano',
          //     img: `${cdnBase}Auchan-logo.jpg`
          //   },
          //   {
          //     name: 'Cora',
          //     href: '',
          //     img: `${cdnBase}`
          //   },
          // ],
          // retailers3: [
          //   {
          //     name: 'Amazon',
          //     href: 'https://www.amazon.fr/stores/Meccano/page/6ABB3D90-AD54-4B59-B0B6-773C5A6F4F2A?ref_=ast_bln',
          //     img: `${cdnBase}amazon+(1).jpg`
          //   },
          //   {
          //     name: 'Cultura',
          //     href: 'https://www.cultura.com/univers-enfant/jeux-jouets/jeux-de-construction/jeux-de-construction-mecanique/meccano.html',
          //     img: `${cdnBase}Culura-logo.jpg`
          //   },
          //   {
          //     name: 'Fnac',
          //     href: 'http://recherche.fnac.com/SearchResult/ResultList.aspx?SCat=0%211&Search=meccano&sft=1&sa=0',
          //     img: `${cdnBase}Fnac-logo.jpg`
          //   },
          //   {
          //     name: 'C-discount',
          //     href: 'https://www.cdiscount.com/search/10/meccano.html#_his_',
          //     img: `${cdnBase}C-Discount-logo.jpg`
          //   },
          //   {
          //     name: 'Robot Advance',
          //     href: 'https://www.robot-advance.com/cat-meccano-199.htm',
          //     img: `${cdnBase}Robot-advance-logo.jpg`
          //   },
          // ]
        }
      },
      variants: {
        id: 'variants',
        header: 'Discover the Variants',
        profiles: {
          nummybay: {
            header: 'Nummy Bay',
            id: 'nummybay',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Un(i)believably delicious! Nummy Bay is the tastiest place in all of Uni-Verse, where Unis represent their favorite foods!   ",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Nummy Bay Unicorn Images'
          },
          sweettreatslanding: {
            header: 'Sweet Treats Landing',
            id: 'sweettreatslanding',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "These Unis have a real sweet hoof! Inspired by the yummiest desserts, these are the sweetest Unis in the whole Uni-Verse!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Sweet Treats Landing Unicorn Images'
          },
          petpartypeak: {
            header: 'Pet Party Peak',
            id: 'petpartypeak',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "A magical mashup! Get ready for the most whimsical animal-themed unicorns you’ve ever seen!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Pet Party Peak Unicorn Images',
          },
          mist_teriousland: {
            header: 'Mist-Terious Land',
            id: 'mist_teriousland',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "The flavor of life is alive and well with these Unis! Inspired by the beauty of nature, these Unis love the outdoors!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Mist-Terious Land Unicorn Images'
          },
          funtasyland: {
            header: 'Funtasyland',
            id: 'funtasyland',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Ain’t no party like a Uni party! Funtasyland is a world packed full of fun, celebrations, and Unis!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Funtasyland Unicorn Images'
          },
          glam_a_ganza: {
            header: 'Glam-A-Ganza Land',
            id: 'glam_a_ganza',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Bea-Uni-Ful! Glam-A-Ganza Land is a fashion-lovers paradise! Every Uni here LOVES fashion, makeup, and glitter!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Glam-A-Ganza Land Unicorn Images'
          },
          superstar_city: {
            header: 'SuperStar City',
            id: 'superstar_city',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Where you can be as Uni-que as you want! Superstar City is the place to be whatever or whomever you want! ",
            bgImage: `https://via.placeholder.com/400x250g`,
            bgImage_alt: 'SuperStar City Unicorn Images'
          }
        }
      }

    },
    collection: {
      title: 'Instructions Guides',
      nextPage: 'Next Page',
      prevPage: 'Prev Page',
      all: 'All',
      wantIt: 'Want it',
      haveIt: 'Have it',
      flipIt: 'Facts',
      scent: 'Scent',
      rarity: 'Rarity',
      color: 'Color',
      showing: 'Showing',
      filters: [
        'scent',
        'rarity',
        'color'
      ]
    },
    support: {
      title: 'Aide',
      subTitle: 'Aide',
      redDivider: 'https://meccano2021.s3.amazonaws.com/Global_Red-Divider.png',
      supportBanner: {
        img: {
          src: 'https://meccano2021.s3.amazonaws.com/Image-Band.jpg',
          alt: ''
        },
      },
      softwareDownloadSection: {
        instructions: {
          header: `A la recherche de notices ?`,
          img: '',
          subHeader: 'Retrouve toutes les notices en cliquant ci-dessous !',
          ctaLabel: 'LES NOTICES',
          ctaLink: '/instructions'
        },
        customerService: {
          header: 'Service client',
          img: '',
          subHeader: `Notre équipe est là pour t'aider pour toute question ou pièce manquante !`,
          ctaLabel: `Obtenir de l'aide`,
          ctaLink: 'https://www.spinmaster.com/en-US/customer-support'
        },
        tutorialVids: {
          header: 'Vidéos démos et plus encore !',
          img: '',
          subHeader: 'Découvre toutes nos vidéos démos sur notre chaîne YouTube !',
          ctaLabel: 'Regarder maintenant',
          src: 'https://www.youtube.com/user/meccanotoys',
          ctaLink: 'https://youtube.com/playlist?list=PL0wwRqT4b_QLc3i0xYlv35NtTuLXb79cF'
        },
        /*softwareSupport: {
          header: 'Aide et mise à jour des logiciels',
          img: '',
          subHeader: 'Tu as des questions sur la programmation de ton robot Meccano ? Retrouve les informations ci-dessous.',
          ctaLabel: 'En savoir plus',
          ctaLink: ''
        },*/
        softwareSupport: {
          header: 'Support logiciel',
          header2: '& Mises à jour',
          backToSupport_link: "/support",
          softwareUpdate : {
            title: "M.A.X. et Meccanoid",
            title2: "Mise à jour du logiciel",
            desc: "Téléchargez notre logiciel de mise à jour des robots pour profiter pleinement de l'expérience robotique ou pour changer la langue de votre robot.",
            forMac: "Pour Mac",
            forWindows: "Pour Microsoft",
            systemReq: "CONFIGURATION REQUISE",
            systemReqMac: "Configuration requise MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 et 10.12 avec les derniers Service Packs;Processeur double cœur 2.0 GHz ou mieux;2 Go de RAM ou plus;2 Go d`espace disque disponible;Affichage XGA (1024 x 768);1 port USB disponible'],
            systemReqWindows: "Configuration requise Windows",
            windowsDesc: ['Windows Vista (32/64 bit) sauf Starter Edition - avec les derniers Service Packs;Windows 7 (32/64 bit) et Windows 8 desktop mode y compris Starter Edition - avec les derniers Service Packs;Windows 10;Processeur dual core 2.0 GHz ou mieux;2GB de RAM ou plus;2GB d`espace disque disponible;écran XGA (1024 x 768);1 port USB disponible']
          },
          programmingEnv: {
            title: "Code Micronoid",
            title2: "Environnement de programmation",
            forMac: "Pour Mac",
            forWindows: "Pour Microsoft",
            systemReq: "EXIGENCES DU SYSTÈME",
            systemReqMac: "Configuration requise MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 et 10.12 avec les derniers Service Packs;Processeur double cœur 2.0 GHz ou mieux;2 Go de RAM ou plus;2 Go d`espace disque disponible;Affichage XGA (1024 x 768);1 port USB disponible'],
            systemReqWindows: "Configuration requise Windows",
            windowsDesc: ['Windows Vista (32/64 bit) sauf Starter Edition - avec les derniers Service Packs;Windows 7 (32/64 bit) et Windows 8 desktop mode y compris Starter Edition - avec les derniers Service Packs;Windows 10;Processeur dual core 2.0 GHz ou mieux;2GB de RAM ou plus;2GB d`espace disque disponible;écran XGA (1024 x 768);1 port USB disponible']
          },
          img: '',
          subHeader: 'Vous avez des questions sur la programmation de votre robot Meccano ? Vous trouverez ici des informations sur l`assistance logicielle.',
          ctaLabel: 'EN SAVOIR PLUS',
          ctaLink: 'http://www.meccano.com/media/download/software/RobotUpdaterSoftwareV1.28.5.zip',
          ctaLinkMac: 'http://www.meccano.com/media/download/software/RobotUpdaterSoftware_V1.23.pkg',
          meccanoid_app:{
            title: 'Application Meccanoid',
            desc: 'Voulez-vous programmer votre robot avec votre appareil intelligent ? Téléchargez l`application gratuite et programmez des mouvements et/ou des sons.',
            googlePlay_link: 'https://play.google.com/store/apps/details?id=com.spinmaster.meccanoidrobot&hl=en',
            googlePlayImg: `${cdnBase}Icons+and+Buttons/playstore%402x.png`,
            googleplay_alt: "Bouton Google Play",
            appStore_link: 'https://itunes.apple.com/us/app/meccanoid/id961199082?mt=8',
            appStoreImg: `${cdnBase}Icons+and+Buttons/appstore%402x.png`,
            appStore_alt: "Bouton de l'App Store d'Apple",
            compatible_devices_title: "APPAREILS COMPATIBLES",
            compatible_devices_list: 
            'Apple iOS, iPad Air 9.3.3, iPad Air 2 9.3.3, iPad Pro 9.7 9.3.3, iPad Pro 12.9" 9.3.3, iPad Mini 9.3.3, iPad Mini 2 9.3.2, iPad Mini 3 9.3.3, iPad Mini 4 9.3.3, iPad 3 9.3.3, iPad 4 9.3.3, iPad 4 10 Beta, iPhone SE 9.3.3, iPhone 4s 9.3.3, iPhone 5 9.3.3, iPhone 5s 9.3.3, iPhone 5c 9.0, iPhone 6 9.3.3, iPhone 6 10 Beta, iPhone 6S 9.3.3, iPhone 6 Plus 9.3.3, iPod Touch 5G 9.3.3, iPod Touch 6G 9.3.3, Android, Asus Nexus 7" 6.0.1, LG Nexus 4 4.4.2, LG Nexus 5 6.01, LG Nexus 5x 6.0.1, HTC Nexus 9 6.0.1, HTC One M8 5.0.1, HTC One M9 5.0.2, Huawei P9 6.0.1, LG G2 4.4.2, LG G3 5.0, LG G4 (2) 6.0, Motorola Moto G (2014 / 2nd gen) 4.4.4, Motorola Moto G (2015 / 3rd gen) 5.1.1, Motorola Moto X (2014) 5.0, Motorola Nexus 6 7.0 Beta, Samsung Galaxy S4 4.4.2, Samsung Galaxy S5 5.0, Samsung Galaxy S6 6.0.1, Samsung Galaxy S6 Edge 6.0.1, Samsung Galaxy S7 Edge 6.0.1, Samsung Galaxy S7 6.0.1, Samsung Galaxy Note 2 4.4.2, Samsung Galaxy Note 3 LTE 5.0, Samsung Galaxy Note 4 6.0., Samsung Galaxy Note 5 6.0.1, Galaxy Note Edge 5.1.1, Galaxy Tab S 8,4" 5.0, Sony Xperia Tablet Z2 4.4.4, Sony Xperia Tablet Z3 Compact 4.4.4, Sony Xperia Z2 5.0.2, Sony Xperia Z2 Tablet 5.1.1, Sony Xperia Z3 5.1.1, Sony Xperia Z5 6.0.1'
          }
        }
      }
    },
    collection: {
      title: 'Notices de montage',
      nextPage: 'Page suivante',
      prevPage: 'Page précédente',
      all: 'Tous',
      wantIt: 'Vouloir',
      haveIt: 'L`avoir',
      flipIt: 'Faits',
      scent: 'Parfum',
      rarity: 'Rareté',
      color: 'Couleur',
      showing: 'Affichage',
      filters: [
        'parfum',
        'Rareté',
        'Couleur'
      ]
    },
    toys: {
      title: 'Jouets',
      subTitle: 'Jouets',
      learn: 'apprendre',
      more: 'plus'
    },
    toy: {
      title: 'apprendre',
      subTitle: 'apprendre',
      backToToys: 'Retour à Jouets',
      features: 'Caractéristiques',
      image: 'Image',
      contents: 'Contenu',
      whereToBuy: 'Où acheter ?',
      instructions: 'Notices',
      videoInstructions: 'Vidéos démos',
      brandSite: 'Site de la marque',
      buyNow: 'Acheter maintenant',
      retailers: 'Détaillants',
      readmore: 'En savoir plus...'
    },
    videos: {
      title: 'Vidéos',
      subTitle: 'Vidéos',
      playVideo: 'Jouer la vidéo',
      playButton_Alt: 'bouton de lecture',
      defaultVideoIndex: 0,
      categories: 'Catégories',
      catList: ['all', 'commercials', 'influencer'],
      videoList: [
        {
          title: 'Publicité 1',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'Publicité 2',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'influencer Video',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'influencer'],
        },
        
      ],
    },
    nomatch: {
      hero: {
        bodyText: "Oups ! Cette page est en construction.",
        backgroundImageDesktop: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage.jpg`,
        backgroundImageMobile: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage-Mobile.jpg`,
        altText: "",
      },
      ctaLabel: "Retourner à l'accueil",
      cta_link: '/',
      title: 'Page Not Found'
    }
  },
}
import React, { Component } from 'react';
import { lang } from '../../il8n/lang';
import { connect } from 'react-redux';
import { toggleInterstitial } from '../../Actions/Index';
import ReactGA from 'react-ga';
import './Interstitial.css';

// Images
import CloseButtonImage from './images/molecule-btn-icon-round-thick-exit-default.png';

/**
 * Interstitial Component.
 * @constructor
 * @prop {json} interstitial - A redux driven JSON representation of this interstitials state and content.
 */

class Interstitial extends Component {
  /**
  * Navigate and track a links exit
  */
  navigateAndTrack() {
    let { interstitial } = this.props;
    ReactGA.event({ category: 'Retailer', action: 'Click', label: interstitial.label });
    window.open(
      interstitial.url,
      '_blank'
    );
    this.props.dispatch(toggleInterstitial(false, '', ''))
  }
  /**
  * Get interstitial content.
  * @return {jsx} The interstitial content.
  */
 /*
  getContent() {
    let { interstitial } = this.props;
    if (interstitial.active) {
      return (
        <div className="interstitial">
          <div className="interstitial__content">
            <button className="interstitial__close" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}>
              <img src={CloseButtonImage} alt="close button" />
            </button>
            <div className='interstitial__title' dangerouslySetInnerHTML={{ __html: lang.interstitial.heading }} />
            <p className='interstitial__text'>{lang.interstitial.copy}</p>
            <div className='interstitial__links'>
              <a className="interstial__link" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))} target="_blank" rel="noopener noreferrer">{lang.interstitial.cancel}</a>
              <a className="interstial__link" onClick={() => this.navigateAndTrack()}>{lang.interstitial.continue}</a>
            </div>
          </div>
        </div>
      );
    }
  }
  */
 getContent(){
  let {interstitial} = this.props;
  console.log("-------");
  console.log(this.props);
  if(interstitial.active && !interstitial.isIframe){
    return (
      <div className="interstitial">
        <div className="interstitial-content">
          <div className="interstitial-close" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}/>
          <h2>{lang.interstitial.heading}<br />{lang.interstitial.heading1} </h2>
          <p>{lang.interstitial.copy}</p>
          <div className="interstial-links">
            <a onClick={() => this.navigateAndTrack()}>{lang.interstitial.continue}</a>
            <a onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))} target="_blank" rel="noopener noreferrer">{lang.interstitial.cancel}</a>
          </div>
        </div>
      </div>
    );
  }
  if(interstitial.active && interstitial.isIframe){
    return (
      <div className="interstitial">
        <div className="interstitial-content interstitial-content-iframe">
          <div className="interstitial-close" onClick={() => this.props.dispatch(toggleInterstitial(false, '', ''))}/>
          <iframe width="1000" height="550" src={interstitial.url} />
        </div>
      </div>
    );
  }
}
  render() {
    return (
      <div>
        {this.getContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    interstitial: state.interstitial
  };
}

export default connect(mapStateToProps)(Interstitial);

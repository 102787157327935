import { cdnBase } from '../../const';

export default {
  mx_mx: {
    langKeys: {
      en_us: 'English (US)',
      en_gb: 'English (UK)',
      en_au: 'English (AU)',
      en_ca: 'English (Canada)',
      fr_fr: 'France',
      nl_nl: 'Netherlands',
      de_de: 'German',
      it_it: 'Italy',
      cz_cz: 'Czech Republic',
      hu_hu: 'Hungary',
      pl_pl: 'Poland',
      ro_ro: 'Romania',
      sk_sk: 'Slovakia',
      mx_mx: 'Mexico',
      es_es: 'Spain',
      tr_tr: 'Turkish',
      hr_hr: 'Croata',
      ru_ru: 'Russian'
    },
    interstitial: {
      heading: 'You are leaving the Spin Master website.',
      copy: `Al hacer click en "Continuar" saldrá de este sitio web y llegará a un sitio de terceros, que se rige por diferentes términos y políticas de privacidad.`,
      continue: 'Continuar',
      cancel: 'Cancelar'
    },
    ga: {
      copy: 'Este sitio web utiliza cookies, al hacer clic en" Aceptar cookies ", usted acepta el almacenamiento de cookies en su dispositivo para mejorar la navegación del sitio, analizar el uso del sitio y ayudar en nuestros esfuerzos de marketing. Puede actualizar sus preferencias de cookies a través de la Configuración de cookies.',
      continue: 'Aceptar Cookies',
      disable: 'Configuración de Cookies'
    },
    brand: 'Meccano',
    applinks: {
      apple: 'LINK TO APPLE APP',
      google: 'LINK TO GOOGLE APP'
    },
    chooseRegion: 'Choose Language',
    loading: 'Loading',
    select: 'Select',
    clearAll: 'Borrar Todo',
    enterReference: 'Enter Reference Number',
    enterProducts: 'Enter Products',
    enterKeywords: 'Enter Keywords',
    clearSearch: 'Clear Search',
    filters: 'Filters',
    filter: 'Filtro',
    details: 'Details',
    learnMore: 'Learn More',
    instructions: 'download Instructions',
    download: 'Descargar',
    videoInstructions: 'video Instructions',
    selectProductType:'Select Product Type',
    instructionGuides: 'Guías de Instrrucciones',
    instructionGuidesSubtitle: 'Busca por tipo de producto, palabra clave (p. Ej., Junior, superdeportivo) o número de referencia (p. Ej., 21101, 15202)',
    refernceNumberTitle: 'Reference Number',
    findReferenceNumber: 'Find your reference number',
    productType: 'Tipo de Producto',
    keywords: 'Keywords',
    referencePopupTitle: '¿Dónde está mi número de referencia?',
    referencePopupDesc: `Tu número de referencia está justo debajo de la clasificación de edad en el lado izquierdo del empaque del producto. También puedes encontrar el número de referencia en la página del producto de nuestro sitio web`,
    backToSupport: '',
    warnings: {
      enableIframes: 'You Must Enable iFrames to view this video'
    },
    helmet: {
      siteTitle: 'MECCANO | Juguetes STEM Para Niños | Ingeniería y Robótica',
      meta_description: '¡La aventura se construye con Meccano! ¡Con los juguetes de construcción Meccano, las posibilidades son infinitas! Explora la ciencia, la tecnología, la ingeniería y las matemáticas, ¡y da vida a tus construcciones!',
      tags: `spin;master;`

    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/products',
          display: 'Juguetes'
        },
        {
          type: 'internal_hash',
          href: '#wheretobuy',
          display: 'Dónde Comprar'
        },
        {
          type: 'internal',
          href: '/instructions',
          display: 'Instrucciones'
        },
        {
          type: 'internal',
          href: '/support',
          display: 'Ayuda'
        }
      ]
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Contáctanos",
      privacy: 'Política de Privacidad',
      terms: 'Términos de Servicio',
      support: 'Ayuda',
      copy: '© 2021 Spin Master Ltd. Todos los derechos reservados',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us',
      social_media_links: [
        {
          title: "Youtube",
          img: `${cdnBase}Icons+and+Buttons/Footer_YouTube-Default.png`,
          link: 'https://www.youtube.com/user/meccanotoys'
        },
      ]
    },
    home: {
      title: 'Home',
      whereToBuy: 'Dónde Comprar',
      videoCTA: {
        src: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`
      },
      sections: {
        banner: {
          slides: [
            {
              heading: '¡Construye y transforma 10 vehículos de carreras diferentes!',
              copy: 'Construye y personaliza 10 vehículos de carreras de alta velocidad diferentes con instrucciones fáciles de usar, o usa tu imaginación para inventar el tuyo propio.',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-101-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-10in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Míralo ahora',
                  link: `${cdnBase}videos/10-in-1_MultiModel_15sTVC-INTL_SMMEC21150H_FINAL.mp4`
                },
                ,
                {
                  type: "link",
                  copy: 'Más información',
                  link: '/products/778988358559'
                }
              ]
            },
            {
              heading: '¡Construye 25 autos deportivos motorizados diferentes!',
              copy: 'Ponte a prueba para construir 25 autos de ensueño diferentes. ¡Un motor de 6 voltios y luces delanteras y traseras darán vida a tus construcciones!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-251-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-25in1-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Míralo ahora',
                  link: `${cdnBase}videos/25-in-1_MultiModel_15sTVC-INTL_SMMMM211_50H_FINAL.mp4`
                },
                {
                  type: 'link',
                  copy: 'Más información',
                  link: '/products/778988374245'
                }
              ]
            },
            {
              heading: '¡Construye tu propio Monster Jam Grave Digger!',
              copy: '¡Este Meccano Junior Grave Digger tiene suspensión funcional! ¡Usa los stickers incluidos para darle a tu Monster Jam el estilo y la arrogancia del show real!',
              type: 'img',
              bg: {
                src: '',
                poster: `${cdnBase}Meccano-Hero-MJ-Banner-V3.jpg`,
                posterMobile: `${cdnBase}MobileHome-Hero-Banner-GraveDigger-V3.jpg`,
                poster_alt: ''

              },
              ctas: [
                {
                  type: 'video',
                  copy: 'Míralo ahora',
                  link: `${cdnBase}videos/MJ_GraveDigger_20sTVC_SMMJR21_00HFINAL_h264.mov`
                },
                {
                  type: 'link',
                  copy: 'Más información',
                  link: '/products/778988359396'
                }
              ]
            },

          ]
        },
        instructions_videos: {
          bgImage: `${cdnBase}Home-Support-Background-Pieces.png`,
          img_alt: 'nuts and bolts ',
          instructions:
          {
            lookingfor: 'Buscando',
            instructions: 'Instrucciones',
            or: 'o',
            support: 'Ayuda',
            question_mark: '?',
            cta1: 'Instrucciones',
            cta1_link: '/instructions',
            cta2: 'Consigue Ayuda',
            cta2_link: '/support'
          },
          tutorial_videos: {
            watch: 'Mira',
            tutorial_videos: 'Videos de Tutoriales',
            and_more: 'y más!',
            cta: 'Mira Ahora',
            src: 'https://www.youtube.com/user/meccanotoys',
            cta_link: `${cdnBase}Home-Support-Image-3.jpg`,
          },
          videoLine: {
            video_src: `${cdnBase}Home-Support-Video_HighRes.mp4`,
            img1: `${cdnBase}Home-Support-Image-2.jpg`,
            img1_alt: 'meccano car',
            img2: `${cdnBase}Home-Support-Image-3.jpg`,
            img2_alt: 'meccano'
          }

        },
        productCarouselHeader: 'Productos imperdibles de Meccano',

        discoverLicensedProducts: {
          header: 'Descubre nuestros Productos de Licencias',
          bgImage: `${cdnBase}`,
          logos: [
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_MonsterJam.png`,
              imgAlt: `Monster Jam`,
              link: '/products?filter=cat_meccano_theme_monster_jam'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_Ducati.png`,
              imgAlt: `Ducati`,
              link: '/products?filter=cat_meccano_theme_ducati'
            },
            {
              img: `https://meccano2021.s3.amazonaws.com/Home-Licensed_John+Deer.png`,
              imgAlt: `John Deere`,
              link: '/products?filter=cat_meccano_theme_john_deere'
            },
          ],
          cta: {
            copy: 'PRODUCTOS DE LICENCIAS',
            link: '/products?filter=cat_meccano_product_type_licensed'
          }

        },
        featuresProducts: {
          header: 'Meccano Must-Haves',
          bgImage: `${cdnBase}`

        },
        reviewsSlides: {
          slides: [
            {
              copy: 'Un regalo perfecto para el niño al que le gusta construir cosas.',
              author: '- Norman',
              img: `${cdnBase}Home-Review-Background-Images-Quote1.png`
            },
            {
              copy: '¡Este juguete se mantuvo a las expectativas a través de los años!',
              author: '- Amy D.',
              img: `${cdnBase}Home-Review-Background-Images-Quote2.png`
            },
            {
              copy: '¡Mi sobrino de once años le encantó!',
              author: '- Christopher M.',
              img: `${cdnBase}Home-Review-Background-Images-Quote3.png`
            }

          ]
        },
        about: {
          heading: 'Acerca de Meccano',
          bgImage: `${cdnBase}`,
          redbar: `${cdnBase}Global_Red-Divider.png`,
          redbarAlt: 'divider',
          copy: [
            "El sólido legado de Meccano, como uno de los sistemas de construcción más antiguos del mundo, comenzó en 1898, cuando Frank Hornby lo inventó en Inglaterra. Al otro lado del Océano Atlántico en 1913, se introdujo en los Estados Unidos un conjunto de construcción similar llamado Erector. En 2000, estas dos marcas innovadoras se fusionaron y comenzaron a construirse juntas bajo una marca global: Meccano.",
            "Más de 100 años después, los valores originales y el objetivo de Meccano siguen siendo los mismos: inspirar a los constructores de todo el mundo a dar vida a sus inventos. Desde estructuras básicas hasta programación robótica de alta tecnología, Meccano ofrece algo para todos. En Meccano, nuestra visión es construir un mejor mañana al inspirar a la próxima generación de Creadores.<em>¡La aventura se construye con Meccano!</em>"
          ],
          btmImg: `${cdnBase}Home-About-Toolbox.png`,
          timeline1: [
            {
              year: '1898',
              copy: 'Frank Hornby inventa el primer kit Meccano en Inglaterra',
              img: `${cdnBase}Home-About-1898.png`,
              imgAlt: ''
            },

            {
              year: '1959',
              copy: 'Se construye una nueva fábrica en Calais, Francia',
              img: `${cdnBase}Home-About-1959.png`,
              imgAlt: ''
            },

            {
              year: '2015',
              copy: 'El galardonado Meccanoid llega al mercado',
              img: `${cdnBase}Home-About-2015.png`,
              imgAlt: ''
            },

            {
              year: '2021',
              copy: 'Meccano se convierte en la marca mundialmente reconocida que inspira a los constructores del mañana',
              img: `${cdnBase}Home-About-2021.png`,
              imgAlt: ''
            }
          ],
          timeline2: [

            {
              year: '1913',
              copy: 'Alfred Carlton Gilbert crea el primer set Erector para niños en los EE. UU',
              img: `${cdnBase}Home-About-1913.png`,
              imgAlt: ''
            },

            {
              year: '2000',
              copy: 'Meccano y Erector se fusionan y comienzan a construir juntos',
              img: `${cdnBase}Home-About-2000.png`,
              imgAlt: ''
            },

            {
              year: '2017',
              copy: 'Meccano lanza nuevos diseños centrados en STEM',
              img: `${cdnBase}Home-About-2017.png`,
              imgAlt: ''
            }
          ]

        },

        collectAll: {
          title: "Collect them all!",
          img: `${cdnBase}Images+%2B+Illustrations/collectors-glamfoxy.png`,
          img_alt: "Glam Foxy zoobles",
          downloads: [
            {
              title: "Zoobles & Z-Girlz",
              desc: "25 + to collect!",
              img: `${cdnBase}Collector+Sheets/zoobles-zgirlz-thumbnail.jpg`,
              cta: "Download",
              cta_link: `${cdnBase}`
            },
            {
              title: "Opposite Obsessed",
              desc: "8 to collect!",
              img: `${cdnBase}Collector+Sheets/opposite-obsessed-thumbnail.jpg`,
              cta: "Download",
              cta_link: `${cdnBase}`
            }
          ]
        },
        retailers: {
          header: 'Dónde Comprar',
          bgImg: `${cdnBase}Where-to-Buy-Background.jpg`,
          retailers: [
            {
              name: 'Amazon',
              href: 'https://www.amazon.com.mx/s?k=meccano&__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&ref=nb_sb_noss',
              img: `${cdnBase}retailers/mx/amazon_logo_RGB.png`
            },
            {
              name: 'Liverpool',
              href: 'https://www.liverpool.com.mx/tienda?s=meccano',
              img: `${cdnBase}retailers/mx/liverpool.jpg`
            },
            {
              name: 'Palacio de Hierro',
              href: 'https://www.elpalaciodehierro.com/buscar?q=meccano',
              img: `${cdnBase}retailers/mx/LOGO%2BEPH%2BNEGRO.png`
            },
            {
              name: 'Juguetrón',
              href: 'https://www.juguetron.mx/catalogsearch/result/?q=meccano',
              img: `${cdnBase}retailers/mx/Logo-Juguetron-01.png`
            },
            {
              name: 'Mercado Libre',
              href: 'https://tienda.mercadolibre.com.mx/spin-master',
              img: `${cdnBase}retailers/mx/mercado-libre.jpg`
            },
            {
              name: 'Walmart',
              href: 'https://www.walmart.com.mx/productos?Ntt=meccano',
              img: `${cdnBase}retailers/mx/Walmart-Logo.png`
            },
          ]
        }
      },
      variants: {
        id: 'variants',
        header: 'Discover the Variants',
        profiles: {
          nummybay: {
            header: 'Nummy Bay',
            id: 'nummybay',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Un(i)believably delicious! Nummy Bay is the tastiest place in all of Uni-Verse, where Unis represent their favorite foods!   ",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Nummy Bay Unicorn Images'
          },
          sweettreatslanding: {
            header: 'Sweet Treats Landing',
            id: 'sweettreatslanding',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "These Unis have a real sweet hoof! Inspired by the yummiest desserts, these are the sweetest Unis in the whole Uni-Verse!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Sweet Treats Landing Unicorn Images'
          },
          petpartypeak: {
            header: 'Pet Party Peak',
            id: 'petpartypeak',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "A magical mashup! Get ready for the most whimsical animal-themed unicorns you’ve ever seen!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Pet Party Peak Unicorn Images',
          },
          mist_teriousland: {
            header: 'Mist-Terious Land',
            id: 'mist_teriousland',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "The flavor of life is alive and well with these Unis! Inspired by the beauty of nature, these Unis love the outdoors!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Mist-Terious Land Unicorn Images'
          },
          funtasyland: {
            header: 'Funtasyland',
            id: 'funtasyland',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Ain’t no party like a Uni party! Funtasyland is a world packed full of fun, celebrations, and Unis!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Funtasyland Unicorn Images'
          },
          glam_a_ganza: {
            header: 'Glam-A-Ganza Land',
            id: 'glam_a_ganza',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Bea-Uni-Ful! Glam-A-Ganza Land is a fashion-lovers paradise! Every Uni here LOVES fashion, makeup, and glitter!",
            bgImage: `https://via.placeholder.com/400x250`,
            bgImage_alt: 'Glam-A-Ganza Land Unicorn Images'
          },
          superstar_city: {
            header: 'SuperStar City',
            id: 'superstar_city',
            thumbnail: `https://via.placeholder.com/400x250`,
            copy: "Where you can be as Uni-que as you want! Superstar City is the place to be whatever or whomever you want! ",
            bgImage: `https://via.placeholder.com/400x250g`,
            bgImage_alt: 'SuperStar City Unicorn Images'
          }
        }
      }

    },
    collection: {
      title: 'Instructions',
      nextPage: 'Next Page',
      prevPage: 'Prev Page',
      all: 'All',
      wantIt: 'Want it',
      haveIt: 'Have it',
      flipIt: 'Facts',
      scent: 'Scent',
      rarity: 'Rarity',
      color: 'Color',
      showing: 'Showing',
      filters: [
        'scent',
        'rarity',
        'color'
      ]
    },
    backToSupport: {
      title: 'BACK TO SUPPORT'
    },

    support: {
      title: 'Support',
      subTitle: 'Support',
      redDivider: 'https://meccano2021.s3.amazonaws.com/Global_Red-Divider.png',
      supportBanner: {
        img: {
          src: 'https://meccano2021.s3.amazonaws.com/Image-Band.jpg',
          alt: ''
        },
      },
      softwareDownloadSection: {
        instructions: {
          header: `¿Buscas instrucciones?`,
          img: '',
          subHeader: 'Haz clic a continuación para encontrar exactamente lo que necesitas.',
          ctaLabel: 'Encontrar instrucciones',
          ctaLink: '/instructions'
        },
        customerService: {
          header: 'Servicio al cliente',
          img: '',
          subHeader: '¿Te faltan piezas o tienes alguna pregunta para nosotros? ¡Nuestro equipo de servicio al cliente está aquí para ayudar!',
          ctaLabel: 'Obtén ayuda',
          ctaLink: 'https://www.spinmaster.com/en-US/customer-support'
        },
        tutorialVids: {
          header: 'Vídeos tutoriales y más.',
          img: '',
          subHeader: 'Visita nuestro canal de YouTube para ver videos tutoriales de Meccano, unboxings y más.',
          ctaLabel: 'Ver ahora',
          ctaLink: 'https://www.youtube.com/user/meccanotoys'
        },
        softwareSupport: {
          header: 'Asistencia',
          header2: 'y actualizaciones de software',
          backToSupport_link: "/support",
          softwareUpdate : {
            title: "M.A.X. and Meccanoid",
            title2: "Software Update",
            desc: "Download our robot update software to get the full robot experience or change your robot's language ",
            forMac: "For Mac",
            forWindows: "For Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          programmingEnv: {
            title: "Micronoid Code",
            title2: "Programming Environment",
            forMac: "For Mac",
            forWindows: "For Microsoft",
            systemReq: "SYSTEM REQUIREMENTS",
            systemReqMac: "System Requirements MAC OSX",
            macDesc: ['Mac OS 10.6, 10.7, 10.8, 10.9, 10.10, 10.11 and 10.12 with the latest Service packs', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port'],
            systemReqWindows: "System Requirements Windows",
            windowsDesc: ['Windows Vista (32/64 bit) excluding Starter Edition - with the latest Service Packs.', 'Windows 7 (32/64 bit) and Windows 8 desktop mode including Starter Edition - with the latest Service packs', 'Windows 10', 'Dual core processor 2.0 GHz or better', '2GB of RAM or more', '2GB of available hard-disk space', 'XGA display (1024 x 768)', '1 available USB port']
          },
          img: '',
          subHeader: 'Have questions about programming your Meccano Robot? Find information on software support here.',
          ctaLabel: 'LEARN MORE',
          ctaLink: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftwareV1.28.5.zip',
          ctaLinkMac: 'http://www.cdn.meccano.com/media/download/software/RobotUpdaterSoftware_V1.23.pkg',
          meccanoid_app:{
            title: 'Meccanoid App',
            desc: 'Do you want to program your robot with your smart device? Download the free app and program movements and/or sounds.',
            googlePlay_link: 'https://play.google.com/store/apps/details?id=com.spinmaster.meccanoidrobot&hl=en',
            googlePlayImg: `${cdnBase}Icons+and+Buttons/playstore%402x.png`,
            googleplay_alt: "Google Play Button",
            appStore_link: 'https://itunes.apple.com/us/app/meccanoid/id961199082?mt=8',
            appStoreImg: `${cdnBase}Icons+and+Buttons/appstore%402x.png`,
            appStore_alt: "Apple App Store Button",
            compatible_devices_title: "COMPATIBLE DEVICES",
            compatible_devices_list: 
            'Apple iOS, iPad Air 9.3.3, iPad Air 2 9.3.3, iPad Pro 9.7 9.3.3, iPad Pro 12.9" 9.3.3, iPad Mini 9.3.3, iPad Mini 2 9.3.2, iPad Mini 3 9.3.3, iPad Mini 4 9.3.3, iPad 3 9.3.3, iPad 4 9.3.3, iPad 4 10 Beta, iPhone SE 9.3.3, iPhone 4s 9.3.3, iPhone 5 9.3.3, iPhone 5s 9.3.3, iPhone 5c 9.0, iPhone 6 9.3.3, iPhone 6 10 Beta, iPhone 6S 9.3.3, iPhone 6 Plus 9.3.3, iPod Touch 5G 9.3.3, iPod Touch 6G 9.3.3, Android, Asus Nexus 7" 6.0.1, LG Nexus 4 4.4.2, LG Nexus 5 6.01, LG Nexus 5x 6.0.1, HTC Nexus 9 6.0.1, HTC One M8 5.0.1, HTC One M9 5.0.2, Huawei P9 6.0.1, LG G2 4.4.2, LG G3 5.0, LG G4 (2) 6.0, Motorola Moto G (2014 / 2nd gen) 4.4.4, Motorola Moto G (2015 / 3rd gen) 5.1.1, Motorola Moto X (2014) 5.0, Motorola Nexus 6 7.0 Beta, Samsung Galaxy S4 4.4.2, Samsung Galaxy S5 5.0, Samsung Galaxy S6 6.0.1, Samsung Galaxy S6 Edge 6.0.1, Samsung Galaxy S7 Edge 6.0.1, Samsung Galaxy S7 6.0.1, Samsung Galaxy Note 2 4.4.2, Samsung Galaxy Note 3 LTE 5.0, Samsung Galaxy Note 4 6.0., Samsung Galaxy Note 5 6.0.1, Galaxy Note Edge 5.1.1, Galaxy Tab S 8,4" 5.0, Sony Xperia Tablet Z2 4.4.4, Sony Xperia Tablet Z3 Compact 4.4.4, Sony Xperia Z2 5.0.2, Sony Xperia Z2 Tablet 5.1.1, Sony Xperia Z3 5.1.1, Sony Xperia Z5 6.0.1'
          }
        }
      }
    },
    toys: {
      title: 'Toys',
      subTitle: 'Toys',
      learn: 'learn',
      more: 'more'
    },
    toy: {
      title: 'Toy',
      subTitle: 'Toy',
      backToToys: 'Back to Toys',
      features: 'Features',
      image: 'Image',
      contents: 'Contents',
      whereToBuy: 'Dónde Comprar',
      instructions: 'Instructions',
      videoInstructions: 'MIRAR VIDEO',
      brandSite: 'brand site',
      buyNow: 'Buy Now',
      retailers: 'Retailers',
      readmore: 'Read More...'
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Play Video',
      playButton_Alt: 'play button',
      defaultVideoIndex: 0,
      categories: 'Categories',
      catList: ['all', 'commercials', 'influencer'],
      videoList: [
        {
          title: 'Commercial 1',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'Commercial 2',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'commercials'],
        },
        {
          title: 'influencer Video',
          video: `${cdnBase}videos/CandyLocks_Recut_30_v3.13a.mp4`,
          thumb: 'https://via.placeholder.com/400x250',
          type: 'default',
          categories: ['all', 'influencer'],
        },
        // {
        //   title:'Youtube Video',
        //   video:`Sx3ORAO1Y6s`,
        //   thumb: 'https://via.placeholder.com/400x250',
        //   type: 'youtube',
        //   categories: ['all','influencer'],
        // }
      ],
    },
    nomatch: {
      hero: {
        bodyText: "¡Vaya! Esta página aún está en construcción.",
        backgroundImageDesktop: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage.jpg`,
        backgroundImageMobile: `https://swimways2020.s3.amazonaws.com/home/404-BackgroundImage-Mobile.jpg`,
        altText: "",
      },
      ctaLabel: "Volver a Inicio",
      cta_link: 'https://www.google.com',
      title: 'Page Not Found'
    }
  },
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MainMenu.css';
import { Link } from 'react-router-dom';
import { lang } from '../../../il8n/lang';
import { connect } from 'react-redux';
import { changeLanguage, changeActiveVid } from '../../../Actions/Index';

import localeIcon from './Images/atom-icon-nav-globe.svg';

import { blockedLocales, siteMapByLocale} from '../../../const';
import { initialLangSet } from '../../../index'





/**
 * The main application menu.
 * @constructor
 */

class LocaleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      menuClass: '',

    };


  }

  /**
  * Sets the language in redux.
  * @param {event} event
  */
  setLang(event){
    if(event.target.value.includes('http')){
      
      window.location.href = event.target.value
      // event.target.value
    }else{
      this.props.dispatch(changeLanguage(event.target.value));
      this.props.dispatch(changeActiveVid(lang.videos.videoList[0]));
      // window.location.pathname(`${lang.getLanguage()}`);
      window.location.replace(`/${lang.getLanguage()}`);
    }
    
  }


  /**
  * Returns all available languages to populate the menu.
  * @returns {jsx} - Menu options
  */
  getAvailableLanguages(){
    let options = [];
    let availableLangs = lang.getAvailableLanguages();
    for(let i = 0; i < availableLangs.length; i++){
      if(!blockedLocales.includes(availableLangs[i])){
        options.push(<option key={ i } value={ availableLangs[i] }>{ lang.langKeys[availableLangs[i]] }</option>)
      }
    }
    return options;
  }

  render() {  
    const intlLangKeys = [
      {
        key: 'en_CA',
        label: 'English (CAN)'
      },
      {
        key: 'en_UK',
      label: 'English (UK)'
      },
      // {
      //   key: 'au-au',
      //   label: 'English (AU)'
      // },
      // {
      //   key: 'fr_FR',
      //   label: 'France'
      // },
      {
        key: 'nl_NL',
        label: 'Netherlands'
      },
      // {
      //   key: 'fr-be',
      //   label: 'Belgium'
      // },
      {
        key: 'de_DE',
        label: 'Germany'
      },

      {
        key: 'ru_RU',
        label: 'Russia'
      },
      {
        key: 'es_ES',
        label: 'Spain',
      },
      {
        key: 'it_IT',
        label: 'Italy'
      },
      {
        key: 'mx_MX',
        label: 'Mexico',
      },
      // {
      //   key: 'hu-hu',
      //   label: 'Hungary'
      // },
      // {
      //   key: 'pl-pl',
      //   label: 'Poland',
      //   ext: '/detail/778988148259'
      // },
      // {
      //   key: 'ro-ro',
      //   label: 'Romania',
      //   ext: '/detail/778988148259'
      // },
      // {
      //   key: 'sk-sk',
      //   label: 'Slovakia',
      //   ext: '/detail/778988148259'
      // }
    ];

    const pathLang = window.location.pathname.split('/')[1];

    if(pathLang !== lang.getLanguage() && pathLang){
      if (pathLang in siteMapByLocale){
        lang.setLanguage(pathLang);
      } else {
        lang.setLanguage('en_us');
      }
    }
    
    let { menuClass } = this.state;
    return (
      <div className="localebar">
          <div className="wrap">
            <nav className='localebar__loc'>
              <img className='localebar__loc__icon' src={localeIcon} alt='location icon'/>
              <select className='localebar__loc__select' onChange={ this.setLang.bind(this) }>
                <option value={lang.getLanguage()}>{ lang.getLanguage() }</option>
                { this.getAvailableLanguages() }
                {/* {intlLangKeys.map((lang, lang_index)=>(
                  <option key={ 'intl_'+lang_index } value={`/${lang.key}${lang.ext ? lang.ext : ''}`}>{ lang.label }</option>
                ))} */}
              </select>
            </nav>
          </div>
        </div>

    );
  }
}

function mapStateToProps(state) {
   return { lang: state.lang };
}

LocaleBar.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(LocaleBar);

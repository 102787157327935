import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MainMenu.css';
import { Link } from 'react-router-dom';
import { lang } from '../../../il8n/lang';
import { connect } from 'react-redux';
// import { changeLanguage, changeActiveVid } from '../../../Actions/Index';
import { breakXSmall, breakSmall, breakTablet, breakLaptop, siteMapByLocale} from '../../../const';
import logo from './Images/logo.png';
import Localebar from './Localebar';

// import localeIcon from './Images/icon_location.png';
import { toggleInterstitial } from '../../../Actions/Index';
// import { blockedLocales } from '../../../const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { HashLink } from 'react-router-hash-link';



/**
 * The main application menu.
 * @constructor
 Props:

  - collaspe(boolean): toggle collapsing behaviour
  - centered(boolean): change placement of logo
 */

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      menuClass: '',
      lastScrollTop: 0,
      activeClass: 'active',
      path: window.location.pathname,
      mobileView: window.innerWidth > breakXSmall
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderToggle = this.renderToggle.bind(this);

  }

  componentWillMount() {
    // When this component mounts, begin listening for scroll changes
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // If this component is unmounted, stop listening
    window.removeEventListener('scroll', this.handleScroll);
  }



  /**
  * handle scroll
  * @param {event} event
  */

  handleScroll(e) {

    if (this.state.collapse) {
      if (this.state.menuClass !== 'active') {
        const currentScrollTop = document.documentElement.scrollTop;

        // Set the state of hidden depending on scroll position
        // We only change the state if it needs to be changed
        if (currentScrollTop > 300 && currentScrollTop > this.state.lastScrollTop) {
          this.setState({ menuClass: 'mod-hidden' });
        } else if (this.state.menuClass === 'mod-hidden') {
          this.setState({ menuClass: '' });
        }
        this.setState({ lastScrollTop: currentScrollTop });
      }
    }

  }
  /**
  * Toggles the main menu.
  * @param {string} requestor - Which element was clicked to initiate this call.
  */
  toggleMainMenu(togglesetting) {
    let { menuClass } = this.state;
    if (window.innerWidth <= breakLaptop) {
      if (menuClass === 'active') {
        this.setState({
          menuClass: ''
        });
        this.setState({
          toggleicon: 'hide'
        });
      } else {
        if (togglesetting !== "nomobile") {
          this.setState({
            menuClass: 'active'
          });

          this.setState({
            toggleicon: 'active'
          });
        }

      }
    }
  }

  renderToggle() {

    return (
      <nav className='main-menu__toggle' onClick={() => this.toggleMainMenu('logo')}>
        <FontAwesomeIcon className={`bars ${this.state.toggleicon}`} icon={faBars} />
        <FontAwesomeIcon className={`exit ${this.state.toggleicon}`} icon={faTimes} />
      </nav>
    )
  }
  /**
  * Renders either the centered layout or left aligned layout
  * @returns {jsx} - The nav item
  */
  renderMenu() {
    let { menuClass } = this.state;
    const pathLang = window.location.pathname.split('/')[1];

    if(pathLang !== lang.getLanguage() && pathLang){
      if (pathLang in siteMapByLocale){
        lang.setLanguage(pathLang);
      } else {
        lang.setLanguage('en_us');
      }
    }
    return (
      <header className={menuClass}>
        <div className="menu__wrap">
          <div className='main-menu'>
            <div className="main-menu_logo_wrapper">
              <Link to={`/${lang.getLanguage()}`} className='main-menu__logo' onClick={() => { this.toggleMainMenu('nomobile'); this.setState({ path: nav.href }); }}>
                <img className="mainlogo" src={logo} alt={lang.brand} />
                <img className="mobilelogo" src={logo} alt={lang.brand} />
              </Link>
            </div>


            <nav className='main-menu__links'>
              <ul>
                {lang.navigation.mainmenu.map((nav, index) => (
                  this.getNavItem(index, nav)
                ))}
                <div className="desktoponly-menu">
                  <Localebar></Localebar>
                </div>
              </ul>
              {/* <div className="blueLine" /> */}
            </nav>
            <ul className="mobile-menu-footLinks">
              <li>
                <Localebar></Localebar>
              </li>
              <li>
                <a href={lang.footer.contactLink} target="_blank">
                  {lang.footer.contactUs}
                </a>
              </li>
              <li>
                <a href={lang.footer.privacyLink} target="_blank">
                  {lang.footer.privacy}
                </a>
              </li>
              <li>
                <a href={lang.footer.termsLink} target="_blank">
                  {lang.footer.terms}
                </a>
              </li>
              {/* <li>
                  <a href={lang.footer.supportLink} target="_blank">
                  {lang.footer.support}
                </a>
                </li> */}
              <li>
                <a id="cookiepref-header">
                  {lang.footer.cookiesPref}
                </a>
              </li>
            </ul>
            <div className="social-media-links-mainnav">
              {lang.footer.social_media_links.map((icon, index) => (
                <div key={icon.title} className='social_media_icon' onClick={() => this.props.dispatch(toggleInterstitial(true, icon.link))}>
                  <img className="socialmedia-img-mobile" id={`${icon.title}`} src={icon.img} alt={icon.title} />
                </div>
              ))}
            </div>
            {this.renderToggle()}

          </div>
        </div>
      </header>


    )

  }
  /**
  * Gets the nav item. Supports 4 types in nav.type : 'internal','internal_hash','external','interstitial' & 'function'.
  * @param {int} index - Current item index.
  * @param {json} nav - Nav item properties.
  * @returns {jsx} - The nav item
  */
  getNavItem(index, nav) {
    let { retailersModal } = this.props;
    let mobileOnly = nav.mobileOnly ? 'mobile' : ''
    let activeClass = this.state.path.includes(nav.href) || this.state.path.includes(nav.display) ? 'active' : '';
    switch (nav.type) {
      case 'internal':
        return (
          <li key={`navigation-${index}`} className={`${mobileOnly} ${activeClass}`}>
            <Link to={`/${lang.getLanguage()}${nav.href}`} onClick={() => { this.toggleMainMenu(); this.setState({ path: nav.href }); window.scrollTo(0, 0); }}>
              <span>{nav.display}</span>
            </Link>
          </li>
        );
      case 'internal_hash':
        return (
          <li key={`navigation-${index}`} className={`${mobileOnly} ${activeClass}`}>
            <HashLink smooth to={`/${lang.getLanguage()}${nav.href}`} onClick={() => { this.toggleMainMenu(); this.setState({ path: nav.href }); }}>
              <span>{nav.display}</span>
            </HashLink>
          </li>
        );
      case 'external':
        return (
          <li key={`navigation-${index}`} className={`${mobileOnly} ${activeClass}`}>
            <a href={nav.href} target='_blank' rel='noopener noreferrer' onClick={() => { this.toggleMainMenu(); this.setState({ path: nav.href }); }}>
              <span>{nav.display}</span>
            </a>
          </li>
        );
      case 'interstitial':
        return (
          <li key={`navigation-${index}`} className={`${mobileOnly} ${activeClass}`}>
            <a onClick={() => { this.props.dispatch(toggleInterstitial(true, nav.href)); { this.toggleMainMenu(); this.setState({ path: nav.href }); } }} rel='noopener noreferrer'>
              <span>{nav.display}</span>
            </a>
          </li>
        );
      case 'function':
        return (
          <li key={`navigation-${index}`} className={`${mobileOnly} ${activeClass}`}>
            <a onClick={retailersModal} rel='noopener noreferrer'>
              <span className="desktop-navigation mobile-navigation">{nav.display}</span>
            </a>
          </li>
        );
      default:
        console.error(new Error('Nav items must specify type.'));
    }
  }

  render() {
    let { menuClass } = this.state;
    return (
      <header className={menuClass}>
        <div className="wrap">
          {this.renderMenu()}


        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return { lang: state.lang };
}

MainMenu.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(MainMenu);

import LocalizedStrings from 'react-localization'

/* eslint-disable camelcase */
// import en_ca from './en_ca'
import en_us from './en_us'
import fr_fr from './fr_fr'
import en_gb from './en_gb'
import mx_mx from './mx_mx'
import it_it from './it_it'




export const lang = new LocalizedStrings({
  // ...en_ca,
  ...en_us,
  ...fr_fr,
  ...en_gb,
  ...mx_mx,
  ...it_it
})


// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { lang } from './il8n/lang';
import { siteMapByLocale } from './const';

// Analytics
// import ReactGA from 'react-ga';
import {UA} from './const';
import TagManager from 'react-gtm-module'
 


// Redux
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { connect } from 'react-redux';




// Page Wrappers
import App from './App/App';
import AsyncComponent from './components/Async/AsyncComponent';

// Global Assets
/*eslint-disable no-unused-vars*/
/*eslint-enable no-unused-vars*/

// Global CSS
import './index.css';

// Localized Overide CSS
import './localeOverrides/en_us.css';
import fr_fr from './il8n/fr_fr';

// import {initialState} from './Reducers/Main';


// Pages
const Home = AsyncComponent(() => import('./components/Pages/Home/Home'));
const Support = AsyncComponent(() => import('./components/Pages/Support/Support'));
const Videos = AsyncComponent(() => import('./components/Pages/Videos/Videos'));
const Toys = AsyncComponent(() => import('./components/Pages/Toys/Toys'));
const Toy = AsyncComponent(() => import('./components/Pages/Toy/Toy'));
const NoMatch = AsyncComponent(() => import('./components/Pages/NoMatch/NoMatch'));
const Instructions = AsyncComponent(() => import('./components/Pages/Instructions/Instructions'));
const SoftwareSupport = AsyncComponent(() => import('./components/Pages/SoftwareSupport/SoftwareSupport'));

const Collection = AsyncComponent(() => import('./components/Pages/Collection/Collection'));
const HTMLContent = AsyncComponent(() => import('./components/Pages/HTMLContent/HTMLContent'));

const createBrowserHistory = require("history").createBrowserHistory;

// Create the Store
let { store, persistor } = configureStore();


const GEOIP = async ()=>{
  let geo_ip_langset
  const access_key = '16109961051f2040a97cc3a320b398dc';

  await fetch('https://api.ipstack.com/check?access_key='+ access_key)
    .then(res => res.json())
    .then(
      (result) => {
        geo_ip_langset= 'mx' == result.country_code.toLowerCase() ? 'mx_mx' : `${result.location.languages[0].code}_${result.country_code.toLowerCase()}`;
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('ip check not working', error)
      }
    )

  return geo_ip_langset;
}

const tagManagerArgs = {
  gtmId: 'GTM-TW38RK'
}

TagManager.initialize(tagManagerArgs)

// Analytics
// ReactGA.initialize(UA);
function logPageView(){

  if(store.getState().gaToggle.status){
    window.scrollTo(0, 0); // scroll page to top

    // ReactGA.set({ page: window.location.pathname + window.location.search });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
}



// Set Lang on Load (Seems Messy)
const pathLang = window.location.pathname.split('/')[1];
if (pathLang !== lang.getLanguage() && pathLang) {
  if (pathLang in siteMapByLocale) {
    lang.setLanguage(pathLang);
  } else {
    lang.setLanguage('en_us');
  }
} else if (!pathLang) {
  GEOIP().then(v => {
    if (v in siteMapByLocale == false){
      window.location.replace('/en_us');
    }else {
      window.location.replace('/'+v);
    }
  });
}

const history = createBrowserHistory();

history.listen(function (location) {
  logPageView();
});

logPageView();

// Define Routes
const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/:locale',
    exact: true,
    component: Home
  },
  {
    path: '/:locale/support',
    exact: true,
    component: Support
  },
  {
    path: '/:locale/softwaresupport',
    exact: true,
    component: SoftwareSupport
  },
  {
    path: '/:locale/videos',
    exact: false,
    component: Videos
  },
  {
    path: '/:locale/products/:pid',
    exact: false,
    component: Toy
  },
  {
    path: '/:locale/products',
    exact: true,
    component: Toys
  },
  {
    path: '/:locale/instructions',
    exact: true,
    component: Instructions
  },
  {
    path: '/:locale/collection',
    exact: true,
    component: Collection
  },
  {
    path: '',
    exact: false,
    component: NoMatch
  },
];

ReactDOM.render(
  // let {gaToggle} = this.props;


  <Provider store={ store }>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter onUpdate={logPageView}>
        <div>
          <App>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={ index }
                  path={ route.path }
                  exact={ route.exact }
                  component={ route.component }
                />
              ))}
            </Switch>
          </App>
        </div>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

export const cdnBase = 'https://meccano2021.s3.amazonaws.com/';
// export const atgBase = process.env.NODE_ENV === 'development' ? 'https://staging.spinmasterstudios.com' : 'http://feed.spinmasterstudios.com/';
// export const atgBase = 'http://feed.spinmasterstudios.com/';
// export const atgBase ='https://prodfeed.spinmasterstudios.com/';
export const atgBase ='https://prodfeed.spinmasterstudios.com/';

export const sampleBase = 'http://zglwowoon2.execute-api.us-east-1.amazonaws.com/';
// Feb 4, 2021 https://salsifystaging.spinmasterstudios.com/rest/service/sm/de/catalog/ProductList?root=cat_spinmaster_games


export const atgBrand = 'cat_meccano';
export const salsifyBrand = 'cat_meccano';

export const atgProductDetailRoot = 'https://shop.spinmaster.com/product/';
export const UA = 'UA-102631505-25';
export const collectionServer = 'http://lampclusterlarge.spinmasterstudios.com:1337/collection/candylocks/';

// If you want to eject from react create app and not have to define these twice you could try this:
// https://github.com/nordnet/sass-variable-loader
// These are the same breaks as in _vars.sass
export const breakXSmall = 540;

export const breakSmall = 768;

export const breakTablet = 992;

export const breakLaptop = 1200;

export const breakXLaptop = 1600;

export const breakXLarge = 1600;


// $device-xs: 540px
// $device-sm: 768px
// $device-md: 992px
// $device-lg: 1200px

// To add a new locale, you must add it on this list
export const siteMapByLocale = {
  'en_us': 'us',
  'fr_fr': 'fr',
  'en_gb': 'uk',
  'mx_mx': 'mx',
  'it_it': 'it'
  // 'en_ca': 'ca',
};


// set to true if you want locale to display component
// export const localeBanlist={

// 	appSectionLocale: {
// 		en_us: true
// 	}
	
// }

// If you wish to review locales but not have them appear in the menu, add them here.
export const blockedLocales = [''];
